import React from "react";

function TermsAndConditions() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <section
        className="banner-area banner-bg"
        style={{
          minHeight: "20vh",
          padding: '140px 0px 40px',
        }}
      >
        <div className="w-100">
          <div>
            <h1 className="text-center" style={{ fontFamily: "Outfit" }}>
              Term And Conditions
            </h1>
          </div>
        </div>
      </section>
      <section className="mt-2 py-5" style={{ fontFamily: "Outfit" }}>
        <div className="container">
          <p className="text-light">Last update date:  May 2024</p>
          <h5>Introduction</h5>
          <p className="text-light">
            The following Terms of Use ('Terms') govern your access to and use
            of the Kaspa Nexus token ('$KSPNX'), the Kaspa Nexus' website{" "}
            <a href="https://www.kaspanexus.com">https://www.kaspanexus.com</a>{" "}
            ('Website') and any related services provided by Kaspa Nexus. By
            accessing, using the Website and/or purchasing and using $KSPNX
            token, you agree to comply with and be bound by these terms.
          </p>
          <p className="text-light">
            If you do not agree with these terms, you must refrain from
            accessing or using the Website. These terms of use along with the
            Privacy Policy and Cookies Policy also available on the Website
            constitute your legal agreement between you and Kaspa Nexus.
          </p>
          <p className="text-light">
            You agree not to assign any rights or obligations under these Terms,
            whether by operation of law or otherwise, without our prior written
            consent. We may assign our rights and obligations under these Terms
            in our sole discretion to an affiliate, or in connection with a
            subcontractor, offshore company, acquisition, sale or merger.
          </p>
          <p className="text-light">
            If any paragraphs or clauses contained in this Terms become
            unenforceable or invalid, this would not affect the applicability of
            any of the other parts of this Terms and/or the validity of this
            entire Terms.
          </p>
          <h5>Acceptance</h5>
          <p className="text-light">
            By accessing or using the Website, you acknowledge that you have
            read, understood, and agree to abide by these Terms. These Terms may
            be changed at any later time which can be checked against the “Last
            Updated” date. By either continuing to use the Website and/or
            buying, acquiring or being in the possession of $KSPNX token after
            the updated terms of use, you inherently agree to the modifications
            of these terms of use.
          </p>

          <h5>Description of Service and Project</h5>
          <p className="text-light">
            Kasapa Nexsus offers a digital platform for the presale of the KSPNX
            token. Our services are designed to facilitate users participating
            in the presale, subject to meeting our presale goals.
          </p>
          <p className="text-light">
            Kaspa Nexus is a revolutionizes cryptocurrency mining project based
            on its own Decentralized Stake-to-Mine (DSM) approach, fusing the
            efficiency of the Kaspa blockchain with an innovative tokenized
            model which gives $KSPNX token holders the opportunity to earn
            credits for staked tokens that can later be exchanged for an amount
            of mining time.
          </p>

          <h5>Disclaimer</h5>
          <p className="text-light">
            The content and materials on Kasapa Nexsus website are provided for
            general information purposes only. They are not intended as
            financial, investment, legal, or other professional advice. All
            information regarding the potential project and presale of KSPNX
            token is tentative and subject to change.
          </p>
          <p className="text-light">
            <strong>
              Disclaimers Regarding Mining Infrastructure Ownership:
            </strong>
          </p>
          <ul>
            <li className="text-light">
              By purchasing and/or staking $KSPNX, you acknowledge and
              understand that you are not purchasing any rights, ownership, or
              stake in any mining infrastructure, equipment, or any other
              physical assets related to the Kaspa Nexus project.
            </li>
            <li className="text-light">
              Holders of $KSPNX tokens, which will actually stake their tokens
              in Kaspa Nexus project, will be only entitled to the revenue
              percentage from mining, as described on our Website, and have no
              rights, claims, or entitlements to the mining infrastructure,
              operations, or any decision-making processes related to mining
              operations.
            </li>
            <li className="text-light">
              Kaspa Nexus reserves the right, at its sole discretion, to
              upgrade, modify, or decommission any part of the mining
              infrastructure under their usage without any obligation to notify
              users or seek their approval.
            </li>
          </ul>

          <h5>Risk Acknowledgement</h5>
          <p className="text-light">
            Cryptocurrency investment holds a higher risk level. You acknowledge
            that the value of KSPNX token is highly volatile and that the
            project is dependent on the success of the presale. Kaspa Nexsus
            does not guarantee any specific outcomes or the successful launch of
            the project. In addition, and without derogating from the above you
            acknowledge and agree that:
          </p>
          <ul>
            <li className="text-light">
              Investing in cryptocurrencies, including $KSPNX, carries inherent
              risks. Prices can be highly volatile, and you may lose all or part
              of your investment. We do not provide investment advice, and you
              are solely responsible for your investment decisions.
            </li>
            <li className="text-light">
              You are responsible for maintaining the security of your wallet
              and private keys. Kaspa Nexsus is not liable for any losses or
              damages resulting from your failure to secure your assets
              properly.
            </li>
            <li className="text-light">
              Cryptocurrency regulations vary by jurisdiction. It is your
              responsibility to comply with the laws and regulations in your
              area regarding cryptocurrency use.
            </li>
            <li className="text-light">
              Kaspa Nexsus makes no guarantees or warranties about the value,
              stability, or future performance of $KSPNX tokens.
            </li>
            <li className="text-light">
              Claiming of the $KSPNX tokens after the end of presale can only be
              done with the same wallet from which they were purchased in the
              presale. In the event of losing access, or the compromise of the
              wallet, you agree that we are not responsible for your loss, nor
              are we able to allow you to claim from a different wallet since we
              do not undergo any know your client ('KYC") procedures when
              enabling purchases during the presale.
            </li>
          </ul>

          <h5>Eligibility and Participation</h5>
          <p className="text-light">
            To participate in the presale, you must meet specific eligibility
            criteria, which may include age, jurisdiction, and compliance with
            relevant laws. It is your responsibility to ensure that you are
            legally allowed to participate in the presale in your jurisdiction.
            Without derogating from the above, you must be at least 18 years old
            to use the Website and its services.
          </p>
          <p className="text-light">
            While using the Website, you agree not to:
          </p>
          <ul>
            <li className="text-light">
              Engage in illegal activities, including money laundering, fraud,
              or any other criminal behavior.
            </li>
            <li className="text-light">
              Disrupt the platforms functionality, attempt to hack, or gain
              unauthorized access to our systems.
            </li>
            <li className="text-light">
              Transmit or share any content that is offensive, harmful, engages
              in bullying or violates the rights of others.
            </li>
            <li className="text-light">
              Engage in manipulative trading practices, pump and dump schemes,
              or any other actions that could harm the integrity or reputation
              of the $KSPNX token or its community as these are strictly
              prohibited.
            </li>
            <li className="text-light">
              Take part in any activity that could potentially distribute any
              malwares to either the website, platform or its users. To protect
              against any dangerous activity, we recommend using an antivirus or
              checking you are only accessing the correct Website.
            </li>
          </ul>

          <h5>No Liability</h5>
          <p className="text-light">
            Kaspa Nexus, its developers, employees, and associated entities,
            affiliates and its officers shall not be liable for any loss or
            damage arising from your participation in the presale or use of the
            Website and/or from your use or inability to use the Website or from
            any unauthorized access to or use of our servers and/or any
            information stored therein. The Website and or services are provided
            “as is” and “as available” without any warranties, expressed or
            implied for any particular purpose.
          </p>
          <p className="text-light">
            Although we aim to offer a very secure and reliable service, we
            cannot guarantee that the Website and our services will always be
            secure, timely and free of bugs. There might be situations in which
            some or all services might be suspended for a limited time due to
            technical or maintenance issues. We may also change or suspend parts
            of the services at our sole discretion.
          </p>
          <p className="text-light">
            You agree to indemnify and hold harmless Kaspa Nexus and any of its
            affiliates, and its officers, from any claims, damages, or expenses
            arising from your breach of these Terms.
          </p>

          <h5>Intellectual Property</h5>
          <p className="text-light">
            All content on Kaspa Nexus, including text, graphics, logos, and
            images, is the property of Kaspa Nexus or its licensors and is
            protected by copyright and intellectual property laws.
          </p>

          <h5>Changes to the Terms</h5>
          <p className="text-light">
            Kaspa Nexus has the right to update and modify these Terms at any
            time. We will provide notice of any significant changes, and your
            continued use of the website following such changes will constitute
            your acceptance of the new Terms.
          </p>

          <h5>Governing Law</h5>
          <p className="text-light">
            These Terms shall be governed by and construed in accordance with
            the laws of the jurisdiction where Kaspa Nexus is or shall be
            registered, without regard to its conflict of law provisions.
          </p>

          <h5>Contact Us</h5>
          <p className="text-light">
            If you have questions about this Terms, please contact us at{" "}
            <a href="mailto:support@kaspanexus.com">support@kaspanexus.com</a>.
          </p>
          <hr />
          <p className="text-center text-light">
            Copyright © {currentYear} Kaspa Nexus. All rights reserved.
          </p>
        </div>
      </section>
    </>
  );
}

export default TermsAndConditions;
