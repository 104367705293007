import { Box } from "@mui/material";
import React from "react";

function GiveAway() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <Box
        component="section"
        className="banner-area banner-bg"
        sx={{
          minHeight: "0vh",
          margin: "0",
          padding: { xs: "18% 0px 0px", sm: "12% 0px 0px", md: "5% 0px 0px" },
          "::before": { background: "unset" },
          fontFamily: "Outfit",
        }}
      >
        <div className="w-100">
          <img
            style={{ maxWidth: "100%" }}
            src="assets/img/images/giveaway_banner.png"
            alt="banner"
          />
        </div>
      </Box>
      <section className="pt-5 pb-3" style={{ fontFamily: "Outfit" }}>
        <div className="container">
          <h5 className="text-center">Win $5M With Kaspa Nexus</h5>
          <p className="text-center text-light">
            Participate in Kaspa Nexus's Mega $5M Giveaway and stand a chance to
            be one of the 10 fortunate winners to claim $500,000 worth of KSPNX
            tokens each! Enhance your winning prospects by sharing and
            participating. Complete all tasks to unlock additional bonus
            entries, increasing your odds of winning. Act now and participate to
            seize this opportunity!
          </p>
          <div className="text-center my-5">
            <Box className="text-center mt-3">
              <iframe
                src="https://gleam.io/C5kth/kaspa-nexus-5-million-giveaway"
                className="e-widget no-button"
                title="Giveaway Iframe"
                width="100%"
                height="750px"
              ></iframe>
            </Box>
          </div>
          <hr />
          <p className="text-center text-light">
            Copyright © {currentYear} Kaspa Nexus. All rights reserved.
          </p>
        </div>
      </section>
    </>
  );
}

export default GiveAway;
