import React from "react";

function RoadMap() {
  return (
    <>
      {/* roadMap-area */}
      <section id="roadMap" className="roadmap-area pt-140">
        <div className="container-fluid p-0">
          <div className="row g-0">
            <div className="col-lg-12">
              <div className="section-title text-center mb-70">
                <h2 className="title">Our Roadmap</h2>
              </div>
            </div>
          </div>
          <div className="row roadMap-active">
            <div className="col-lg-4">
              <div className="roadmap-item">
                <span className="roadmap-title">Stage 1</span>
                <div className="roadmap-content">
                  <h4 className="title">
                    <span className="dot" />
                    Foundation and Token Launch
                  </h4>
                  <p>
                    Successfully launch <strong>KSPNX</strong> tokens and
                    establish a solid foundation for the{" "}
                    <strong> Kaspa Nexus</strong> ecosystem. This includes smart
                    contract deployment, security audits, and the initial
                    presale to build our community and secure funding for future
                    developments.
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="roadmap-item">
                <span className="roadmap-title">Stage 2</span>
                <div className="roadmap-content">
                  <h4 className="title">
                    <span className="dot" />
                    Platform Development and Beta Testing
                  </h4>
                  <p>
                    Develop the core features of the{" "}
                    <strong>Kaspa Nexus</strong> platform, including the
                    stake-to-mine protocol and user dashboard. A closed beta
                    phase will allow us to gather valuable feedback and make
                    necessary adjustments, ensuring a seamless user experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="roadmap-item">
                <span className="roadmap-title">Stage 3</span>
                <div className="roadmap-content">
                  <h4 className="title">
                    <span className="dot" />
                    Full Platform Launch
                  </h4>
                  <p>
                    Officially launch the <strong>Kaspa Nexus</strong> platform
                    to the public, enabling users worldwide to participate in
                    decentralized mining. This stage will focus on user
                    acquisition, community engagement, and the establishment of
                    partnerships within the cryptocurrency ecosystem.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="roadmap-item">
                <span className="roadmap-title">Stage 4</span>
                <div className="roadmap-content">
                  <h4 className="title">
                    <span className="dot" />
                    Expansion and Ecosystem Growth
                  </h4>
                  <p>
                    Expand the <strong>Kaspa Nexus</strong> platform through
                    additional features, such as advanced staking options,
                    store, and integration with third parties. This phase aims
                    to solidify <strong>Kaspa Nexus's</strong> position in the
                    market and drive the growth of its user base and token
                    value.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="roadmap-item">
                <span className="roadmap-title">Stage 5</span>
                <div className="roadmap-content">
                  <h4 className="title">
                    <span className="dot" />
                    Sustainability and Innovation
                  </h4>
                  <p>
                    Ensure the long-term sustainability of the{" "}
                    <strong>Kaspa Nexus</strong> platform by continuously
                    exploring new technologies and innovations in the mining and
                    blockchain space. This includes adapting to changes in the
                    cryptocurrency market, environmental considerations, and
                    user needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* roadMap-area-end */}
    </>
  );
}

export default RoadMap;
