import React from "react";

function Brands() {
  return (
    <>
      {/* brand-area */}
      <div className="brand-area">
        <div className="container-fluid p-0">
          <div className="row g-0">
            <div className="col-lg-12">
              <div className="brand-title text-center">
                <h6 className="title">Powered BY</h6>
              </div>
            </div>
          </div>
          <div className="brand-item-wrap">
            <div className="row g-0 brand-active">
              <div className="col-12">
                <div className="brand-item">
                  <img src="assets/img/brand/brand_img01.png" alt="" />
                </div>
              </div>
              <div className="col-12">
                <div className="brand-item">
                  <img src="assets/img/brand/brand_img02.png" alt="" />
                </div>
              </div>
              <div className="col-12">
                <div className="brand-item">
                  <img src="assets/img/brand/brand_img03.png" alt="" />
                </div>
              </div>
              <div className="col-12">
                <div className="brand-item">
                  <img src="assets/img/brand/brand_img04.png" alt="" />
                </div>
              </div>
              <div className="col-12">
                <div className="brand-item">
                  <img src="assets/img/brand/brand_img05.png" alt="" />
                </div>
              </div>
              <div className="col-12">
                <div className="brand-item">
                  <img src="assets/img/brand/brand_img06.png" alt="" />
                </div>
              </div>
              <div className="col-12">
                <div className="brand-item">
                  <img src="assets/img/brand/brand_img07.png" alt="" />
                </div>
              </div>
              <div className="col-12">
                <div className="brand-item">
                  <img src="assets/img/brand/brand_img04.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* brand-area-end */}
    </>
  );
}

export default Brands;
