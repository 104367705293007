/* eslint-disable react/prop-types */

import ReactECharts from "echarts-for-react";
import { graphic } from "echarts";

function Chart({
  lineColor,
  axisColorOne,
  axisColorTwo,
  days,
  values,
  basicColor,
  seriesName,
}) {
  const option = {
    backgroundColor: "transparent",
    xAxis: {
      type: "category",
      axisLine: {
        lineStyle: {
          color: basicColor,
        },
      },
      splitLine: {
        show: false,
      },
      data: days, // Ensure this matches the length of values
      axisLabel: {
        interval: function (index) {
          const totalLabels = days.length; // Use days length to match values
          const showNthLabel = Math.ceil(totalLabels / 5);
          return index % showNthLabel === 0 || index === totalLabels - 1;
        },
        rotate: 30,
        textStyle: {
          color: basicColor,
        },
        formatter: function (value) {
          // Always hide time and only show the date part (e.g., "Jul 19, 2024")
          return value.split(",")[0];
        },
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: basicColor,
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: basicColor,
        },
      },
      min: function (value) {
        return Number(value.min)
          .toFixed(10)
          .replace(/\.?0+$/, "");
      },
      max: function (value) {
        return Number(value.max)
          .toFixed(10)
          .replace(/\.?0+$/, "");
      },
      axisLabel: {
        interval: 0,
        rotate: 10,
        textStyle: {
          color: basicColor,
        },
        formatter: function (value) {
          return Number(value)
            .toFixed(10)
            .replace(/\.?0+$/, "");
        },
      },
    },
    grid: {
      left: "5%",
      right: "4%",
      bottom: window.innerWidth < 500 ? "20%" : "10%",
      containLabel: true,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "line",
        lineStyle: {
          color: basicColor,
        },
      },
      formatter: function (params) {
        let tooltipContent = `${params[0].name}<br/>`;
        params.forEach((item) => {
          const formattedValue = Number(item.value)
            .toFixed(10)
            .replace(/\.?0+$/, "");
          tooltipContent += `
            <span style="display:inline-block;width:10px;height:10px;background-color:${item.color};border-radius:50%;margin-right:5px;"></span>
            ${item.seriesName}: ${formattedValue}<br/>
          `;
        });
        return tooltipContent;
      },
    },
    series: [
      {
        name: seriesName ?? "Series",
        type: "line",
        stack: "Total",
        emphasis: {
          focus: "series",
        },
        color: lineColor,
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: axisColorOne,
            },
            {
              offset: 1,
              color: axisColorTwo,
            },
          ]),
        },
        data: values, // Ensure this matches the length of days
      },
    ],
  };

  return (
    <>
      <ReactECharts
        style={{
          width: "100%",
          height: "100%",
        }}
        option={option}
      />
    </>
  );
}

export default Chart;
