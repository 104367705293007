import React from "react";

function Documents() {
  return (
    <>
      {/* document-area */}
      <section className="document-area pt-5" id="contact-us">
        <div className="container">
          <div className="document-inner-wrap">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb-60">
                  <h2 className="title">Have more questions?</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <div className="document-form-wrap">
                  <h4 className="title">Get In Touch Now</h4>
                  <form action="#">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-grp">
                          <input type="text" placeholder="Your Name" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-grp">
                          <input type="email" placeholder="Your Email" />
                        </div>
                      </div>
                    </div>
                    <div className="form-grp">
                      <textarea
                        name="message"
                        placeholder="Enter you message....."
                        defaultValue={""}
                      />
                    </div>
                    <button type="submit" className="btn">
                      Send Message
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="document-wrap">
                  <h4 className="title">Read Documents</h4>
                  <ul className="list-wrap">
                    <li>
                      <a href="whitepaper.pdf">
                        <span className="icon">
                          <i className="fas fa-file-pdf" />
                        </span>
                        Whitepaper
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.cyberscope.io/audits/kspnx"
                        target="_blank"
                      >
                        <span className="icon">
                          <i className="fas fa-file-pdf" />
                        </span>
                        Audit
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.cyberscope.io/audits/kspnx"
                        target="_blank"
                      >
                        <span className="icon">
                          <i className="fas fa-file-pdf" />
                        </span>
                        KYC
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* document-area-end */}
    </>
  );
}

export default Documents;
