import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import InfoDilouge from "./InfoDilouge";
import { getNetworkContract } from "../../utils";
import { preSaleReadFunction } from "../../ConnectivityAssets/hooks";

export const StyledTableCell = ({ children, position }) => {
  return (
    <TableCell
      align={position ? position : "center"}
      style={{
        borderColor: "#000000",
      }}
    >
      {children}
    </TableCell>
  );
};

function StakingTable({ data, unStakeHandler }) {
  const [openInfoDilouge, setOpenInfoDilouge] = useState({
    text: "",
    status: false,
  });
  const [isPresaleEnded, setisPresaleEnded] = useState(false);
  const [callFunction, setCallFunction] = useState(true);
  const toLocalFormat = (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const initVoidSigner = async () => {
    try {
      const networkContractObj = getNetworkContract(1);
      const preSaleStatusContract = await preSaleReadFunction(
        networkContractObj,
        "isPresaleEnded"
      );
      setisPresaleEnded(preSaleStatusContract);
      setCallFunction(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    initVoidSigner();
    setCallFunction(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callFunction]);
  const handleInfoDilouge = (text, status) => {
    setOpenInfoDilouge({
      text,
      status,
    });
  };
  return (
    <Box
      sx={{
        py: 2,
        mt: { xs: 0, sm: 2 },
        overflow: "scroll",
        height: "350px",
        "&::-webkit-scrollbar": {
          width: 1,
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "none",
        },
        "&::-webkit-scrollbar:horizontal": {
          height: "0px !important",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#3d3e40",
          borderRadius: 2,
        },
      }}
    >
      <InfoDilouge
        text={openInfoDilouge?.text}
        open={openInfoDilouge?.status}
        setOpen={setOpenInfoDilouge}
      />
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          borderRadius: "0px",
          background: "none",
          "&::-webkit-scrollbar": {
            width: 1,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "none",
          },
          "&::-webkit-scrollbar:horizontal": {
            height: "0px !important",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#F6F6F6",
            borderRadius: 2,
          },
        }}
      >
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              {[
                "Staked Tokens",
                "Claimed Tokens",
                "Stake Time",
                "REWARD CLAIMED",
                "",
              ].map((text) => (
                <TableCell
                  key={text}
                  style={{
                    borderColor: "#000000",
                  }}
                  align="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={{ xs: "16px", sm: "18px" }}
                    fontWeight="800"
                    fontFamily="outfit"
                    sx={{
                      color: "#000000",
                    }}
                  >
                    {text}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.length > 0 ? (
              data?.map((dt, i) => (
                <TableRow
                  key={i + 1 + Math.random()}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <StyledTableCell>
                    <Typography
                      variant="subtitle2"
                      fontSize={{ xs: "14px", sm: "16px" }}
                      sx={{
                        fontFamily: "outfit",
                        color: "#000000",
                      }}
                    >
                      {toLocalFormat(dt?.stakedTokens)}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      variant="subtitle2"
                      fontSize={{ xs: "14px", sm: "16px" }}
                      sx={{
                        fontFamily: "outfit",
                        color: "#000000",
                      }}
                    >
                      {toLocalFormat(dt?.claimedTokens)}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      variant="subtitle2"
                      fontSize="16px"
                      sx={{
                        fontFamily: "outfit",
                        color: "#000000",
                      }}
                    >
                      {dt?.stakeTime > 0
                        ? moment.unix(dt?.stakeTime).format("DD/MM/YYYY")
                        : 0}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      variant="subtitle2"
                      fontSize={{ xs: "14px", sm: "16px" }}
                      sx={{
                        fontFamily: "outfit",
                        color: "#000000",
                      }}
                    >
                      {toLocalFormat(dt?.claimedReward)}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button
                      sx={{
                        color: "#dfdfdf",
                        width: "100px",
                        background: "#0F101E",
                        fontSize: "18px",
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        textTransform: "capitalize",
                        fontFamily: "Outfit",
                        borderRadius: "12px",
                        fontWeight: "600",
                        "&.Mui-disabled": {
                          color: "#979EA7",
                        },
                        "&:hover": {
                          background: "#0F101E",
                        },
                      }}
                      onClick={() => {
                        isPresaleEnded
                          ? unStakeHandler(dt?.plainIndex)
                          : handleInfoDilouge(
                              "Unstacking of your $KSPNX will be only available after the successful ending of the presale.",
                              true
                            );
                      }}
                    >
                      Unstake
                    </Button>
                  </StyledTableCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell colSpan={4} align={"center"}>
                  <Typography
                    variant="subtitle2"
                    fontSize="20px"
                    sx={{
                      fontFamily: "outfit",
                      color: "#000000",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    No data
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default StakingTable;
