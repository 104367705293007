import * as React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Box, useMediaQuery } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ open, setOpen }) {
  const matches = useMediaQuery("(max-width:700px)");
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Dialog
        // fullWidth
        open={open}
        TransitionComponent={Transition}
        sx={{
          zIndex: 10,
          "& .MuiPaper-root": {
            backgroundColor: "#101507",
            width: matches ? "100%" : "70%",
          },
        }}
      >
        <Box
          sx={{
            borderRadius: "10px",
            height: "100%",
          }}
        >
          <Box
            py={2}
            pl={3}
            pr={1}
            sx={{
              backgroundImage:
                "linear-gradient(90deg, #0d1824 30%, #0d1824 100%)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              fontSize="22px"
              fontFamily="Outfit"
              fontWeight="700"
              sx={{ fontSize: "18px", color: "#ffffff" }}
            >
              KASPA NEXUS
            </Box>
            <CloseIcon
              onClick={() => handleClose()}
              style={{
                cursor: "pointer",
                color: "#ffffff",
              }}
            />
          </Box>
          <Box width="100%" height="100%">
            {/* <video width="320" height="240" controls autoPlay>
  <source src="movie.mp4" type="video/mp4">
  <source src="movie.ogg" type="video/ogg">
  Your browser does not support the video tag.
</video> */}
            <video
              crossOrigin="anonymous"
              loop
              controls
              muted
              autoPlay
              playsInline
              preload="auto"
              role="presentation"
              tabIndex="-1"
              style={{
                height: "100%",
                objectFit: "cover",
                width: "100%",
                zIndex: 0,
                boxShadow: "rgb(0, 0, 0) 0px 0px 35px 48px inset",
              }}
            >
              <source src="/video.mp4" type="video/mp4" />
            </video>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
