import {
  Box,
  Container,
  Divider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import {
  StyledButton,
  ToastNotify,
} from "../components/SmallComponents/AppComponents";
import {
  copyIcon,
  ethIcon,
  logo,
  metaMaskIcon,
  rpcIcon,
} from "../components/SmallComponents/Images";
import { tokenAddress } from "../ConnectivityAssets/environment";

function AddressCopy() {
  const sampleAddress = tokenAddress;
  const matches = useMediaQuery("(max-width:951px)");
  const matchesTwo = useMediaQuery("(max-width:600px)");
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const copyHandler = () => {
    navigator.clipboard.writeText(sampleAddress);
    setAlertState({
      open: true,
      message: `Address copied`,
      severity: "success",
    });
  };
  const addTokenToWallet = async () => {
    try {
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: "KSPNX",
            decimals: 18,
            image:
              "https://photos.pinksale.finance/file/pinksale-logo-upload/1713870981373-1bb87d41d15fe27b500a4bfcde01bb0e.png",
          },
        },
      });

      if (wasAdded) {
        setAlertState({
          open: true,
          message: `Success! Token Added.`,
          severity: "success",
        });
      } else {
        setAlertState({
          open: true,
          message: "Error! Somthing Went Wrong",
          severity: "error",
        });
      }
    } catch (error) {
      console.log(error, "Error to add token");
    }
  };
  return (
    <Box>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Container maxWidth={"xl"}>
        {matchesTwo ? (
          <Box
            sx={{
              py: 3,
              px: 2,
              my: 7,
              backgroundColor: "#F9F9F908",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Box flexGrow={1}>
                <Box
                  component={"img"}
                  src={logo}
                  width="40px"
                  style={{
                    zIndex: "200",
                  }}
                />
                <Box
                  component={"img"}
                  src={ethIcon}
                  width="30px"
                  sx={{ ml: -1, zIndex: "100" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  mr: -2,
                }}
              >
                <Box
                  component={"img"}
                  src={copyIcon}
                  width="40px"
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={copyHandler}
                />

                <Box component={"img"} src={metaMaskIcon} width="40px" />
                <a href="" target="_blank">
                  <Box component={"img"} src={rpcIcon} width="40px" mr={2} />
                </a>
              </Box>
            </Box>
            <Divider
              sx={{
                background: "#F9F9F91A",
                borderColor: "transparent",
              }}
            />
            <Box mt={2}>
              <Typography
                varinat="subtitle2"
                component="p"
                sx={{
                  color: "#F9F9F9",
                  fontSize: "10px",
                  fontWeight: "400",
                  fontFamily: "Outfit",
                }}
              >
                {sampleAddress}
                <br />
                <span
                  style={{
                    color: "#F9F9F966",
                    fontSize: "8px",
                  }}
                >
                  CONTRACT ADDRESS
                </span>
              </Typography>
              <Typography
                varinat="subtitle2"
                component="p"
                sx={{
                  color: "#F9F9F9",
                  fontSize: "10px",
                  fontWeight: "400",
                  fontFamily: "Outfit",
                  mt: 3,
                }}
              >
                Ethereum ERC-20
                <br />
                <span
                  style={{
                    color: "#F9F9F966",
                    fontSize: "8px",
                  }}
                >
                  NETWORK
                </span>
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              py: 3,
              px: 3,
              my: 7,
              backgroundColor: "#F9F9F908",
              // height: "200px",
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              gap: 2,
            }}
          >
            <Box component={"img"} src={logo} width="50px" />
            <Typography
              varinat="subtitle2"
              component="p"
              sx={{
                color: "#F9F9F9",
                fontSize: "16px",
                fontWeight: "700",
                flexGrow: 1,
                fontFamily: "Outfit",
              }}
            >
              {matchesTwo
                ? sampleAddress.slice(0, 5) + "..." + sampleAddress.slice(-5)
                : sampleAddress}

              <br />
              <span
                style={{
                  color: "#F9F9F966",
                  fontSize: matchesTwo ? "10px" : "12px",
                }}
              >
                CONTRACT ADDRESS
              </span>
            </Typography>

            <Box
              component={"img"}
              src={copyIcon}
              width="40px"
              sx={{
                cursor: "pointer",
              }}
              onClick={copyHandler}
            />

            {matches ? null : (
              <>
                <Divider
                  orientation="vertical"
                  flexItem
                  light
                  sx={{ backgroundColor: "#F9F9F91A" }}
                />
                <Box component={"img"} src={ethIcon} width="40px" />
                <Typography
                  varinat="subtitle2"
                  component="p"
                  sx={{
                    color: "#F9F9F9",
                    fontSize: "14px",
                    fontWeight: "700",
                    mr: 6,
                    fontFamily: "Outfit",
                  }}
                >
                  Ethereum ERC-20
                  <br />
                  <span
                    style={{
                      color: "#F9F9F966",
                      fontSize: "10px",
                    }}
                  >
                    NETWORK
                  </span>
                </Typography>
                <Box
                  component={"img"}
                  src={metaMaskIcon}
                  width="40px"
                  onClick={addTokenToWallet}
                  sx={{
                    cursor: "pointer",
                  }}
                />
                <a href="" target="_blank">
                  <Box component={"img"} src={rpcIcon} width="40px" mr={2} />
                </a>
              </>
            )}
          </Box>
        )}
      </Container>
    </Box>
  );
}

export default AddressCopy;
