import { useMediaQuery } from "@mui/material";
import React from "react";

function Features() {
  const matches = useMediaQuery("(max-width:600px)");
  return (
    <>
      {/* features-area */}
      <section id="feature" className="features-area pt-140 pb-110">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="section-title text-center mb-70">
                <h2 className="title">
                  - Kaspa Nexus -<br />A New Era in Sustainable Crypto Mining
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="features-item">
                <div className="features-content">
                  <h2 className="title">
                    <a href="#!">Lower Entry Barriers</a>
                  </h2>
                  <p>
                    By removing the need for sophisticated hardware and
                    specialized knowledge, we open the doors of mining to a
                    wider audience.
                  </p>
                </div>
                <div className="features-img">
                  <img src="assets/img/images/features_img01.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="features-item">
                <div className="features-content">
                  <h2 className="title">
                    <a href="#!">Enhance Transparency</a>
                  </h2>
                  <p>
                    With blockchain as our foundation, we ensure that every
                    transaction and mining effort is transparent and
                    tamper-proof.
                  </p>
                </div>
                <div className="features-img">
                  <img src="assets/img/images/features_img02.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="features-item">
                <div className="features-content">
                  <h2 className="title">
                    <a href="#!">Foster Decentralization</a>
                  </h2>
                  <p>
                    Our platform is a testament to the ethos of cryptocurrency,
                    promoting a distributed and user-controlled mining
                    environment.
                  </p>
                </div>
                <div className="features-img">
                  <img src="assets/img/images/features_img03.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="features-item">
                <div className="features-content">
                  <h2 className="title">
                    <a href="#!">Maximize Efficiency</a>
                  </h2>
                  <p>
                    Through our optimized mining protocols, we offer unmatched
                    efficiency, reducing waste and ensuring a greater yield.
                  </p>
                </div>
                <div className="features-img">
                  <img src="assets/img/images/features_img04.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="features-area-two features-bg"
        data-background="assets/img/bg/features_bg.png"
      >
        <div className="container">
          <div className="features-inner-wrap">
            <div className="features-item-wrap">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6">
                  <div className="features-item-two">
                    <div className="features-img-two">
                      <img
                        src="assets/img/images/features_two_img01.png"
                        alt=""
                      />
                    </div>
                    <div className="features-content-two">
                      <h2 className="title">
                        Stake KSPNX for Mining Credits or just buy ones
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="features-item-two">
                    <div className="features-img-two">
                      <img
                        src="assets/img/images/features_two_img02.png"
                        alt=""
                      />
                    </div>
                    <div className="features-content-two">
                      <h2 className="title">
                        Burn Your Credits & Start Mining Kaspa
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="features-item-two">
                    <div className="features-img-two">
                      <img
                        src="assets/img/images/features_two_img03.png"
                        alt=""
                      />
                    </div>
                    <div className="features-content-two">
                      <h2 className="title">
                        Transfer Mined Kaspa to your Wallet
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title section-title-two text-center">
                  <h2
                    className="title"
                    style={{ fontSize: matches ? "25px" : "45px" }}
                  >
                    Our mission with Kaspa mining transcends mere acquisition,
                    aiming instead to make the process both accessible and
                    straightforward for everyone. By emphasizing inclusivity, we
                    ensure that individuals from diverse backgrounds can
                    participate in Kaspa mining.
                  </h2>
                </div>
              </div>
            </div>
            <div className="features-line-shape" />
          </div>
        </div>
        <div className="features-shape-wrap">
          <img
            src="assets/img/images/features_shape01.png"
            alt=""
            className="alltuchtopdown"
          />
          <img
            src="assets/img/images/features_shape02.png"
            alt=""
            className="leftToRight"
            style={{ maxWidth: "400px" }}
          />
        </div>
      </section>
    </>
  );
}

export default Features;
