import React from "react";
import PresaleBox from "../components/PresaleBox";
import { Box, Grid, useMediaQuery } from "@mui/material";

function HeroSection() {
  const matches = useMediaQuery("(max-width:900px)");
  return (
    <>
      {/* banner-area */}
      <section
        className="banner-area banner-bg"
        style={{ background: `url('assets/img/banner/banner_bg.png')` }}
        id="home"
      >
        <div className="container">
          <Grid
            container
            spacing={6}
            flexDirection={{ xs: "column-reverse", md: "row" }}
          >
            <Grid item xs={12} md={7}>
              {/* {matches ? (
                ""
              ) : (
                <>
                  <Box
                    sx={{
                      color: "#fdfdfd",
                      fontSize: "20px",
                      fontFamily: "Outfit",
                      lineHeight: "24px",
                      fontWeight: "400",
                      textAlign: "center",
                    }}
                  >
                    Presale will start at May 10th 2024 or few days before.
                    track our site and social media for updates.
                  </Box>
                  <Box mt={2} />
                  <div class="banner-countdown-wrap">
                    <div class="coming-time" data-countdown="2024/5/10"></div>
                  </div>
                </>
              )} */}

              <div
                style={{ marginTop: "100px" }}
                className="banner-content text-center"
              >
                <h2 style={{ textAlign: "center" }} className="title">
                  Forge the Future of Finance
                  <br />
                  <span>- Your Stake, Your Power</span>
                </h2>
                <p style={{ textAlign: "left" }}>
                  Kaspa Nexus emerges as a groundbreaking venture in the realm
                  of cryptocurrency mining, aligning cutting-edge technology
                  with an overarching goal of democratizing the mining process.
                  As the latest entrant in the blockchain space, Kaspa Nexus is
                  poised to redefine mining dynamics by leveraging the Kaspa
                  blockchain's superior speed and scalability. Our mission is to
                  offer a seamless and equitable Kaspa cloud mining experience
                  to all, regardless of their technical expertise or financial
                  capabilities.
                </p>
                {/* <div className="banner-countdown-wrap">
                  <div className="coming-time" data-countdown="2024/8/29" />
                </div> */}
              </div>
            </Grid>
            <Grid item xs={12} md={5}>
              {/* {matches ? (
                <>
                  <Box
                    sx={{
                      color: "#fdfdfd",
                      fontSize: "20px",
                      fontFamily: "Outfit",
                      lineHeight: "24px",
                      fontWeight: "400",
                      textAlign: "center",
                    }}
                  >
                    Presale will start at May 10th 2024 or few days before.
                    track our site and social media for updates.
                  </Box>
                  <Box mt={2} />
                  <div class="banner-countdown-wrap">
                    <div class="coming-time" data-countdown="2024/5/10"></div>
                  </div>
                </>
              ) : (
                ""
              )} */}
              <PresaleBox />
            </Grid>
          </Grid>
          {/* <div
            style={{
              display: "flex",
              flexDirection: matches ? "column-reverse" : "row",
            }}
            className="d-flex flex-sm-column-reverse row justify-content-center"
          >
            <div className="col-lg-7 col-sm-12">

            </div>
            <div className="col-lg-5 col-sm-12">
              
            </div>
          </div> */}
        </div>
        <div className="banner-scroll-down">
          <a href="#contribution" className="section-link">
            <span />
            <span />
            <span />
          </a>
        </div>
        <div className="banner-shape-wrap">
          <img
            src="assets/img/banner/banner_shape01.png"
            alt=""
            className="leftToRight"
          />
          {/* <img
            src="assets/img/banner/banner_shape02.png"
            alt=""
            className="alltuchtopdown"
          /> */}
        </div>
      </section>
      {/* banner-area-end */}
    </>
  );
}

export default HeroSection;
