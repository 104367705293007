import React from "react";

function Charts() {
  return (
    <>
      {/* chart-area */}
      <div id="tokenomics" className="chart-area pt-140">
        <div className="section-title mb-4">
          <h2 style={{ textAlign: "center" }} className="title">
            Tokenomics
          </h2>
        </div>
        <div className="container">
          <div className="chart-inner-wrap">
            <div className="row align-items-center">
              <div className="col-md-6">
                <p className="text-light">
                  Explore the strategic allocation of KSPNX tokens within the
                  Kaspa Nexus ecosystem, designed to fuel growth, reward
                  participation, and empower our community. This chart
                  illustrates our commitment to a sustainable and inclusive
                  mining future.
                </p>
              </div>
              <div className="col-md-6">
                <img src="assets/img/images/tokenomics.png" alt="Tokenomics" />
              </div>
              <div className="col-lg-12">
                <div className="text-center">
                  <h5>Kaspa Nexus Decentralized Stake-to-Mine (DSM) APP</h5>
                  <p className="text-light">
                    The app will help you to manage your KSPNX tokens, mining
                    credits, and mined KASPAs to show you how much you've earned
                    in any time frame you choose.
                  </p>
                </div>
                <div className="right-side-content">
                  <img
                    style={{ maxWidth: "100%" }}
                    src="assets/img/images/tablet_phone.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* chart-area-end */}
    </>
  );
}

export default Charts;
