export const cardContractAddress = "0xC7F1a78bB245D85d5a4a8e4F6e0076122229DD78";
export const defaultAddress = "0x774E412756fD1E7011492B445e66F688d8B9dC0a";
export const tokenAddress = "0x523AF069116E0E7bb10b461ad410ed6a17c0e101";

/// eth contracts
export const ethPresaleAddress = "0xe72D8576087F4606945d6327cD051Be6bca77956";
export const ethUsdcAddress = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";
export const ethUsdtAddress = "0xdAC17F958D2ee523a2206206994597C13D831ec7";

//// bsc///
export const bscPresaleAddress = "0x08cfd25E4F3fDC94DC880655F6c2EDc8c835d5A1";
export const bscUsdcAddress = "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d";
export const bscUsdtAddress = "0x55d398326f99059fF775485246999027B3197955";

////dashboard///
export const stakeTokenAddress = "0x523AF069116E0E7bb10b461ad410ed6a17c0e101";
export const stakingAddress = "0xe64B2519E929FadBd211a4A2721c3FD219a710ad";
