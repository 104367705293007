import React from "react";

function CookiesPolicy() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <section
        className="banner-area banner-bg"
        style={{
          minHeight: "20vh",
          padding: '140px 0px 40px',
        }}
      >
        <div className="w-100">
          <div>
            <h1 className="text-center" style={{ fontFamily: "Outfit" }}>
              Cookies Policy
            </h1>
          </div>
        </div>
      </section>
      <section className="mt-2 py-5" style={{ fontFamily: "Outfit" }}>
        <div className="container">
          <div className="w-100">
            <h2>Cookie Policy for Kaspa Nexus</h2>

            <p className="text-light">
              <strong>Last Update:</strong> May 2024
            </p>

            <h5>1. Introduction</h5>
            <p className="text-light">
              This Cookie Policy applies to{" "}
              <a href="https://www.kaspanexus.com">
                https://www.kaspanexus.com
              </a>{" "}
              ("Website") and any products, forums, and services we offer
              through the Website. This Cookies Policy is designed to help you
              understand how we collect, use, and store cookies and similar
              technologies when you visit our Website. Please take a moment to
              review this policy carefully. By using our website, you consent to
              the use of cookies and similar technologies as described in this
              policy.
            </p>

            <h5>2. What Are Cookies?</h5>
            <p className="text-light">
              Cookies are small text files that are placed on your device when
              you visit a website. They are widely used to make websites work or
              function more efficiently, provide information to the website's
              owners and enhance user experience, such as offering personalized
              content and advertisement.
            </p>

            <h5>3. Use of Cookies</h5>
            <p className="text-light">We use cookies to:</p>
            <ul>
              <li className="text-light">
                To provide and maintain our website's functionality;
              </li>
              <li className="text-light">
                To understand how visitors use our website and improve its
                performance;
              </li>
              <li className="text-light">
                To remember your preferences and provide a personalized
                experience;
              </li>
              <li className="text-light">
                To deliver targeted advertising and measure the effectiveness of
                our campaigns.
              </li>
            </ul>

            <h5>4. Managing Cookies</h5>
            <p className="text-light">
              Some cookies on our website may be placed by third party service
              providers. These third parties may include analytics and
              advertising partners. We do not have control over the cookies they
              set, and their use is governed by their respective privacy
              policies. You have the option to accept or decline cookies. Most
              web browsers automatically accept cookies, but you can usually
              modify your browser setting to decline cookies if you prefer.
            </p>
            <h5>5. Changes to This Policy</h5>
            <p className="text-light">
              We may update this Cookies Policy from time to time to reflect
              changes in our practices or for other operational, legal, or
              regulatory reasons. We encourage you to review this policy
              periodically to stay informed about how we use cookies. By using
              our website, you consent to the use of cookies as described in
              this Cookies Policy. Your continued use of our website constitutes
              your acceptance of any changes or updates to this policy.
            </p>
            <h5>6. Contact Us</h5>
            <p className="text-light">
              If you have questions about this Cookie Policy, please contact us
              at{" "}
              <a href="mailto:support@kaspanexus.com">support@kaspanexus.com</a>
              .
            </p>
          </div>
          <hr />
          <p className="text-center text-light">
            Copyright © {currentYear} Kaspa Nexus. All rights reserved.
          </p>
        </div>
      </section>
    </>
  );
}

export default CookiesPolicy;
