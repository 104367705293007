import React, { useContext, useEffect, useState } from "react";
import { AppContext, getNetworkContract } from "../utils";
import {
  preSaleReadFunction,
  tokenReadFunction,
} from "../ConnectivityAssets/hooks";
import { formatUnits } from "viem";

function Contribution() {
  const { chainId } = useContext(AppContext);
  const [progressBarForAll, setprogressBarForAll] = useState(0);
  const [amountRaisedForAll, setamountRaisedForAll] = useState(0);
  const [totalSoldTokensForAll, setTotalSoldTokensForAll] = useState(0);
  const [callFunction, setCallFunction] = useState(true);

  const toLocalFormat = (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const initVoidSigner = async () => {
    try {
      const networkContractObj = getNetworkContract(1);
      const bscNetworkContractObj = getNetworkContract(56);
      let dec = await tokenReadFunction("decimals");
      dec = +dec.toString();
      let stage = await preSaleReadFunction(networkContractObj, "currentStage");
      let soldTotal = await preSaleReadFunction(
        networkContractObj,
        "soldToken"
      );
      let bscSoldTotal = await preSaleReadFunction(
        bscNetworkContractObj,
        "soldToken"
      );
      let totalTokeSoldContract =
        +parseFloat(+formatUnits(soldTotal?.toString(), dec)).toFixed(0) +
        +parseFloat(+formatUnits(bscSoldTotal?.toString(), dec)).toFixed(0);
      let totalRaisedAmount = 0;
      let harcapForAll = 0;
      for (let index = 0; index <= +stage?.toString(); index++) {
        let presaleData = await preSaleReadFunction(
          networkContractObj,
          "phases",
          [+index]
        );
        let bscPresaleData = await preSaleReadFunction(
          bscNetworkContractObj,
          "phases",
          [+index]
        );
        totalRaisedAmount +=
          +formatUnits(presaleData[2]?.toString(), dec) > 0
            ? +parseFloat(
                +formatUnits(presaleData[2]?.toString(), dec) /
                  +formatUnits(presaleData[0]?.toString(), dec)
              ).toFixed(0)
            : 0;
        totalRaisedAmount +=
          +formatUnits(bscPresaleData[2]?.toString(), dec) > 0
            ? +parseFloat(
                +formatUnits(bscPresaleData[2]?.toString(), dec) /
                  +formatUnits(bscPresaleData[0]?.toString(), dec)
              ).toFixed(0)
            : 0;
        harcapForAll += +parseFloat(
          +formatUnits(bscPresaleData[1]?.toString(), dec) /
            +formatUnits(bscPresaleData[0]?.toString(), dec)
        ).toFixed(0);
      }
      setamountRaisedForAll(toLocalFormat(+totalRaisedAmount));
      setTotalSoldTokensForAll(toLocalFormat(+totalTokeSoldContract));
      let progForAll = (+totalRaisedAmount / +harcapForAll) * 100;
      setprogressBarForAll(+progForAll);
      setCallFunction(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    initVoidSigner();
    setCallFunction(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callFunction, chainId]);
  return (
    <>
      {/* contribution-area */}
      <section id="contribution" className="contribution-area pt-130 pb-130">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="contribution-title">
                <h2 className="title">
                  <span>${amountRaisedForAll}</span> Total Raised
                </h2>
              </div>
              <div className="progress-wrap">
                {/* <ul className="list-wrap">
                  <li>Pre-Sale</li>
                  <li>soft cap</li>
                  <li>bonus</li>
                </ul> */}
                <div
                  className="progress"
                  role="progressbar"
                  aria-label="Example with label"
                  aria-valuenow={83}
                  aria-valuemin={0}
                  aria-valuemax={100}
                >
                  <div
                    className="progress-bar"
                    style={{ width: `${progressBarForAll}%` }}
                  />
                </div>
                <h6 className="progress-title">
                  {totalSoldTokensForAll} KSPNX Sold{" "}
                  <span>${amountRaisedForAll} Raised</span>
                </h6>
              </div>
              <div className="contribution-btn">
                <a
                  href="https://www.cyberscope.io/audits/kspnx"
                  className="btn"
                >
                  Read Audit
                </a>
                <a
                  href="whitepaper.pdf"
                  target="_blank"
                  className="btn btn-two"
                >
                  Read White Paper
                </a>
                <a
                  href="https://www.cyberscope.io/audits/kspnx"
                  target="_blank"
                  className="btn btn-two"
                  rel="noreferrer"
                >
                  KYC
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* contribution-area-end */}
    </>
  );
}

export default Contribution;
