/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { useContext, useState } from "react";
import { stakingWriteFunction } from "../../ConnectivityAssets/hooks";
import Loading from "./loading";
import { ToastNotify } from "./AppComponents";
import { AppContext } from "../../utils";

export const StyledTableCell = ({ children, position }) => {
  return (
    <TableCell
      align={position ? position : "center"}
      style={{
        borderColor: "#000",
      }}
    >
      {children}
    </TableCell>
  );
};

function DataTable({ data, dataFunction }) {
  const { account } = useContext(AppContext);

  const [loading, setLoading] = useState(false);

  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const showAlert = (message, severity = "error") => {
    setAlertState({
      open: true,
      message,
      severity,
    });
  };
  const claimHander = async (index) => {
    if (!account) {
      return showAlert("Error! Please connect your wallet.");
    }
    try {
      setLoading(true);
      await stakingWriteFunction("claimReward", [index]);
      dataFunction();
      setLoading(false);
      showAlert("Claimed successfully!", "success");
    } catch (e) {
      setLoading(false);
      console.log(e);
      showAlert(e?.shortMessage);
    }
  };

  const withdrawHander = async (index) => {
    if (!account) {
      return showAlert("Error! Please connect your wallet.");
    }
    try {
      setLoading(true);
      await stakingWriteFunction("withdraw", [index]);
      dataFunction();
      setLoading(false);
      showAlert("Withdraw successfully!", "success");
    } catch (e) {
      setLoading(false);
      console.log(e);
      showAlert(e?.shortMessage);
    }
  };

  return (
    <Box
      sx={{
        py: 2,
        mt: { xs: 0, sm: 2 },
        overflow: "scroll",
        height: { xs: "250px", md: "250px" },
        "&::-webkit-scrollbar": {
          width: 1,
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "none",
        },
        "&::-webkit-scrollbar:horizontal": {
          height: "0px !important",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#3d3e40",
          borderRadius: 2,
        },
      }}
    >
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          borderRadius: "0px",
          background: "none",
          "&::-webkit-scrollbar": {
            width: 1,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "none",
          },
          "&::-webkit-scrollbar:horizontal": {
            height: "0px !important",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#F6F6F6",
            borderRadius: 2,
          },
        }}
      >
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              {[
                "Sr#",
                "Amount",
                "Received Reward",
                "Claimable Reward",
                "",
                "",
              ].map((text, i) => (
                <TableCell
                  key={text + i + Math.random() + i}
                  style={{
                    borderColor: "#fff",
                  }}
                  align="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={{ xs: "14px", sm: "16px" }}
                    fontWeight="500"
                    fontFamily="outfit"
                    sx={{
                      color: "#fff",
                    }}
                  >
                    {text}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.length > 0 ? (
              data?.map((dt, i) => (
                <TableRow
                  key={i + 1 + Math.random() + i + 1}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <StyledTableCell>
                    <Typography
                      variant="subtitle2"
                      fontSize={{ xs: "12px", sm: "14px" }}
                      sx={{
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                      {i + 1}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      variant="subtitle2"
                      fontSize={{ xs: "12px", sm: "14px" }}
                      sx={{
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                      {dt?.amount}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      variant="subtitle2"
                      fontSize={{ xs: "12px", sm: "14px" }}
                      sx={{
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                      {dt?.claimedReward}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      variant="subtitle2"
                      fontSize={{ xs: "12px", sm: "14px" }}
                      sx={{
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                      {dt?.isActive
                        ? dt?.calculateReward * dt?.claimDuration
                        : 0}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button
                      disabled={!dt?.isActive}
                      sx={{
                        width: { xs: "90px", sm: "100px" },
                        fontSize: "16px",
                        color: "#000",
                        // py: 0.8,
                        height: "41px",
                        borderRadius: "6px",
                        background: "#68BCBB",
                        fontFamily: "outfit",
                        textTransform: "capitalize",
                        "&:hover": {
                          background: "#68BCBB",
                        },
                      }}
                      onClick={() => claimHander(dt?.index)}
                    >
                      Claim
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button
                      disabled={!dt?.isActive}
                      sx={{
                        width: { xs: "90px", sm: "100px" },
                        fontSize: "16px",
                        color: "#000",
                        // py: 0.8,
                        height: "41px",
                        borderRadius: "6px",
                        background: "#68BCBB",
                        fontFamily: "outfit",
                        textTransform: "capitalize",
                        "&:hover": {
                          background: "#68BCBB",
                        },
                      }}
                      onClick={() => withdrawHander(dt?.index)}
                    >
                      Withdraw
                    </Button>
                  </StyledTableCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell colSpan={6} align={"center"}>
                  <Typography
                    variant="subtitle2"
                    fontSize="24px"
                    sx={{
                      fontFamily: "outfit",
                      color: "#fff",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    No data
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default DataTable;
