import React, { createContext, useEffect, useState } from "react";
import { useAccount, useWalletClient, useNetwork } from "wagmi";
import {
  bscPresaleAddress,
  bscUsdcAddress,
  bscUsdtAddress,
  ethPresaleAddress,
  ethUsdcAddress,
  ethUsdtAddress,
} from "./ConnectivityAssets/environment";
import {
  bscPresaleAbi,
  bscUsdcAbi,
  bscUsdtAbi,
  ethPresaleAbi,
  ethUsdcAbi,
  ethUsdtAbi,
} from "./ConnectivityAssets/abi";

let initialState = {
  account: null,
  signer: null,
  chainIdArray: [],
  chainId: 0,
};

export const getNetworkContract = (networkID) => {
  switch (networkID) {
    case 1:
      return {
        presaleAddress: ethPresaleAddress,
        presaleAbi: ethPresaleAbi,
        usdcAddress: ethUsdcAddress,
        usdcAbi: ethUsdcAbi,
        usdtAddress: ethUsdtAddress,
        usdtAbi: ethUsdtAbi,
        chainId: 1,
      };
    default:
      return {
        presaleAddress: bscPresaleAddress,
        presaleAbi: bscPresaleAbi,
        usdcAddress: bscUsdcAddress,
        usdcAbi: bscUsdcAbi,
        usdtAddress: bscUsdtAddress,
        usdtAbi: bscUsdtAbi,
        chainId: 56,
      };
  }
};

export const AppContext = createContext(initialState);
export const ConectivityProvider = ({ children }) => {
  const { address, isDisconnected } = useAccount();
  const { data: signer } = useWalletClient();
  const { chains, chain } = useNetwork();
  const chainIds = chains?.map((info) => info?.id);

  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState({
      account: address ?? null,
      signer: signer ?? null,
    });
  }, [isDisconnected, address, signer]);
  return (
    <AppContext.Provider
      value={{
        account: state.account,
        signer: state.signer,
        chainIdArray: chainIds,
        chainId: chain?.id,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
