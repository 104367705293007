import React from "react";
import { Dialog, DialogContent, Box, Slide, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";

import { StyledButton } from "./AppComponents";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledModal = withStyles(() => ({
  root: {
    "& .MuiDialog-root": {
      zIndex: "1301 !important",
      maxWidth: "400px",
    },
    "&.MuiDialog-container": {
      overflowY: "hidden !important",
    },
    "& .MuiDialog-paperScrollPaper": {
      backgroundColor: "black",
      height: "auto",
      boxShadow: "none",
      //   border: "5px solid black",
      borderRadius: "10px",
    },
    "& .dialoge__content__section": {
      background: "#0d1824!important",
      borderRadius: "10px",
      // border: "5px solid #9022FF",
    },
    "& .MuiDialogContent-root": {
      paddingTop: "20px",
      paddingBottom: "20px",
    },
  },
}))(Dialog);

export default function InfoDilouge({ text, open, setOpen }) {
  const handleClose = () => {
    setOpen({
      text: text,
      status: false,
    });
  };

  return (
    <div className="modal__main__container">
      <StyledModal
        open={open}
        keepMounted
        TransitionComponent={Transition}
        onClose={handleClose}
        // eslint-disable-next-line jsx-a11y/aria-props
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="dialoge__content__section">
          <Box
            borderRadius="100px"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Typography
              mt={2}
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                textAlign: "center",
                color: "#ffffff",
                fontFamily: "Outfit",
              }}
            >
              {text}
            </Typography>
            <Box mt={2}>
              <StyledButton width="230px" onClick={handleClose}>
                Ok
              </StyledButton>
            </Box>
          </Box>
        </DialogContent>
      </StyledModal>
    </div>
  );
}
