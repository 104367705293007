/* eslint-disable react/prop-types */
import { Stack, Typography } from "@mui/material";

import { FaWallet } from "react-icons/fa";
import { toLocalFormat } from "../SmallComponents/AppComponents";

function UserValues({ userBalance }) {
  const dt = [
    {
      name: "KSPNX BALANCE",
      value: `${toLocalFormat(userBalance?.stakingTokenBalance)} KSPNX`,
      icon: <FaWallet />,
      // valueChange: "+19%",
    },
    // {
    //   name: "MINING CREDITS",
    //   value: "1,000 KMC",
    //   icon: <FaWallet />,
    //   valueChange: "+19%",
    // },
    {
      name: "Mining Profit Received",
      value: `${userBalance?.rewardBalance} ETH`,
      icon: <FaWallet />,
      // valueChange: "+11%",
    },
  ];
  return (
    <Stack
      gap={4}
      height={"100%"}
      sx={{
        mt: { xs: 3, md: 0 },
        justifyContent: "center",
      }}
    >
      {dt?.map(({ name, value, icon }) => (
        <Stack key={name} flexDirection={"row"} gap={2} alignItems={"center"}>
          <Stack
            sx={{
              color: "#fff",
              background:
                "linear-gradient(to bottom, #68BCBB, rgb(21, 20, 41))",
              fontSize: "16px",
              p: 1,
              borderRadius: "6px",
            }}
          >
            {icon}
          </Stack>
          <Stack gap={1}>
            <Typography
              variant="h5"
              fontFamily="Outfit"
              sx={{
                fontSize: { xs: "18px", sm: "22px" },
                fontWeight: "500",
                textTransform: "uppercase",
                color: "#68BCBB",
              }}
            >
              {name}
            </Typography>

            <Stack flexDirection={"row"} alignItems={"end"} gap={0.5}>
              <Typography
                variant="h5"
                fontFamily="Outfit"
                sx={{
                  fontSize: { xs: "16px", sm: "18px" },
                  fontWeight: "600",
                  textTransform: "uppercase",
                  color: "#fff",
                }}
              >
                {value}
              </Typography>
              {/* <Typography
                variant="body1"
                fontFamily="Outfit"
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  textTransform: "uppercase",
                  color: "#68BCBB",
                }}
              >
                <FaLongArrowAltUp />
                {valueChange}
              </Typography> */}
            </Stack>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}

export default UserValues;
