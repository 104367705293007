import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";

import { useCallback, useContext, useEffect, useState } from "react";

import { formatUnits, parseUnits } from "viem";
import axios from "axios";
import moment from "moment";

import Chart from "../../components/SmallComponents/Chart";
import UserValues from "../../components/SmallComponents/UserValues";
import DataTable from "../../components/SmallComponents/Table";
import { AppContext } from "../../utils";
import {
  stakingReadFunction,
  stakeTokenReadFunction,
  stakeTokenWriteFunction,
  stakingWriteFunction,
} from "../../ConnectivityAssets/hooks";
import Loading from "../../components/SmallComponents/loading";
import {
  StyledInputTwo,
  ToastNotify,
} from "../../components/SmallComponents/AppComponents";
import { stakingAddress } from "../../ConnectivityAssets/environment";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import NetworkSwitch from "../../NetworkSwitch";

export const dummyDays = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];
export const dummyValues = [0, 10, 20, 30, 40, 50, 60, 70, 80];

function Dashboard() {
  const { open } = useWeb3Modal();
  const { account, chainId } = useContext(AppContext);
  const [userData, setUserData] = useState([]);
  const [openNetworkSwitch, setOpenNetworkSwitch] = useState(false);
  const [hashRateValue, setHashRateValue] = useState(0);
  const [hrGraphStatus, setHrGraphStatus] = useState("1d");
  const [userBalance, setUserBalance] = useState({
    stakingTokenBalance: 0,
    rewardBalance: 0,
  });
  const [stakedChartData, setStakedChartData] = useState({
    categories: [],
    series: [],
  });
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const showAlert = (message, severity = "error") => {
    setAlertState({
      open: true,
      message,
      severity,
    });
  };

  const [hashRateArray, setHashRateArray] = useState({
    categories: [],
    series: [],
  });
  const [usdtProfitArray, setUsdtProfitArray] = useState({
    categories: [],
    series: [],
  });
  const userFunction = useCallback(async () => {
    try {
      const currentTime = moment().format("X");
      const userStakeInfo = await stakingReadFunction("users", [account]);
      const stakingTokenDecimal = await stakeTokenReadFunction("decimals");
      let claimDuration = await stakingReadFunction("claimDuration");
      claimDuration = Number(claimDuration?.toString());

      const stakingTokenBalanceContract = await stakeTokenReadFunction(
        "balanceOf",
        [account]
      );

      const dt = [];
      let userRewardBalance = 0;

      for (let i = 0; i < +userStakeInfo[1]?.toString(); i++) {
        const userData = await stakingReadFunction("userStakes", [account, i]);
        const calculatedRewardContract = await stakingReadFunction(
          "calculateReward",
          [account, i]
        );
        const obj = {
          index: i,
          amount: parseFloat(formatUnits(userData[0]?.toString(), 18))?.toFixed(
            2
          ),
          checkpoint: +userData[1]?.toString(),
          startTime: +userData[2]?.toString(),
          investedWeek: +userData[3]?.toString(),
          claimedReward: parseFloat(formatUnits(userData[4]?.toString(), 18)),
          isActive: userData[5],
          claimDuration: +parseFloat(
            (Number(currentTime) - Number(userData[1]?.toString())) /
              claimDuration
          )?.toFixed(0),
          calculateReward: parseFloat(
            parseFloat(formatUnits(calculatedRewardContract?.toString(), 18))
          )
            ?.toFixed(10)
            ?.replace(/\.?0+$/, ""),
        };
        userRewardBalance += Number(
          parseFloat(formatUnits(userData[4]?.toString(), 18))
        );
        dt.push(obj);
      }
      setUserData(dt);
      setUserBalance({
        stakingTokenBalance: parseFloat(
          formatUnits(
            stakingTokenBalanceContract?.toString(),
            +stakingTokenDecimal?.toString()
          )
        )?.toFixed(0),
        rewardBalance: parseFloat(userRewardBalance)
          ?.toFixed(10)
          ?.replace(/\.?0+$/, ""),
      });
    } catch (e) {
      console.log(e);
    }
  }, [account]);

  useEffect(() => {
    if (account) {
      userFunction();
    }
  }, [account, userFunction]);

  const fetchHashRateData = useCallback(async () => {
    try {
      const { data: hashData } = await axios.get(
        `https://hr.2miners.com/api/v1/hashrate/${hrGraphStatus}/kas`
      );

      let categories = [];
      let seriesData = [];
      let totalHashRate = 0;

      if (hrGraphStatus === "1h") {
        // For '1h', show data points without aggregating by date
        // Sort data by timestamp in ascending order
        hashData.sort((a, b) => a.timestamp - b.timestamp);

        hashData.forEach((entry) => {
          const date = new Date(entry.timestamp * 1000).toLocaleString(
            "default",
            {
              hour: "numeric",
              minute: "numeric",
              day: "numeric",
              month: "short",
              year: "numeric",
            }
          );

          const hashrateValue = Number(
            parseFloat(formatUnits(entry?.hashrate?.toString(), 15))
          );

          categories.push(date);
          seriesData.push(hashrateValue.toFixed(2));
          totalHashRate += hashrateValue;
        });
      } else {
        // Convert and group the hash rate data by day for other time periods
        const groupedData = hashData.reduce((acc, entry) => {
          const date = new Date(entry.timestamp * 1000); // Convert timestamp to milliseconds
          const key = date.toISOString().split("T")[0]; // Format as "YYYY-MM-DD" for day-wise grouping

          const hashrateValue = Number(
            parseFloat(formatUnits(entry?.hashrate?.toString(), 15))
          );

          if (!acc[key]) {
            acc[key] = 0;
          }
          acc[key] += hashrateValue; // Aggregate the hash rate
          return acc;
        }, {});

        // Transform data for the chart
        categories = Object.keys(groupedData).map((key) => {
          const [year, month, day] = key.split("-");
          return new Date(year, month - 1, day).toLocaleDateString("default", {
            day: "numeric",
            month: "short",
            year: "numeric",
          });
        });

        seriesData = Object.values(groupedData);

        // Calculate total hash rate
        totalHashRate = seriesData.reduce((acc, value) => acc + value, 0);

        const sortedData = categories.map((date, index) => ({
          date,
          value: parseFloat(seriesData[index]),
        }));

        sortedData.sort((a, b) => new Date(a.date) - new Date(b.date));

        categories = sortedData.map((item) => item.date);
        seriesData = sortedData.map((item) => item.value.toFixed(2));
      }

      return {
        categories,
        seriesData,
        totalHashRate: totalHashRate.toFixed(3),
      };
    } catch (error) {
      console.error("Error fetching or processing data:", error);
      return null;
    }
  }, [hrGraphStatus]);

  useEffect(() => {
    (async () => {
      try {
        let calculationCounterContract = await stakingReadFunction(
          "calculationCounter"
        );
        calculationCounterContract = +calculationCounterContract?.toString();
        // const { data } = await axios.get(`${serverUrl}/api/total-stakers`);
        const { data: hashRateData } = await axios.get(
          "https://api.kaspa.org/info/hashrate?stringOnly=false"
        );

        // const groupedData = data?.data?.reduce((acc, stake) => {
        //   if (!stake.time || isNaN(new Date(stake.time))) {
        //     console.error("Invalid time value:", stake.time);
        //     return acc;
        //   }

        //   const date = new Date(stake.time);
        //   const dateKey = date.toISOString().split("T")[0]; // Format as "YYYY-MM-DD"

        //   if (!acc[dateKey]) {
        //     acc[dateKey] = 0;
        //   }
        //   acc[dateKey] += stake.amount;
        //   return acc;
        // }, {});

        // // Transform data for chart
        // const categories = Object.keys(groupedData).map((date) => {
        //   const dateObj = new Date(date);
        //   return dateObj.toLocaleString("default", {
        //     month: "short",
        //     day: "numeric",
        //     year: "numeric",
        //   });
        // });

        // const seriesData = Object.values(groupedData);

        const hrRate = parseFloat(
          Number(hashRateData?.hashrate?.toString()?.slice(0, 4)) / 10
        )?.toFixed(2);

        let usdtProfitContract = [];

        for (let i = 0; i <= calculationCounterContract; i++) {
          let calculationDiveder = await stakingReadFunction(
            "calculationDivider",
            [i]
          );
          calculationDiveder = +calculationDiveder?.toString();
          let rewardMultiplier = await stakingReadFunction("rewardMultiplier", [
            i,
          ]);
          rewardMultiplier = +rewardMultiplier?.toString();
          usdtProfitContract.push(
            parseFloat(rewardMultiplier / calculationDiveder)
              .toFixed(10)
              .replace(/\.?0+$/, "")
          );
        }

        setHashRateValue(
          hrRate
          // parseFloat(
          //   formatUnits(hashRateData?.hashrate?.toString(), 14)
          // )?.toFixed(2)
        );

        // setStakedChartData({
        //   categories,
        //   series: seriesData,
        // });
        setUsdtProfitArray({
          categories: [],
          series: usdtProfitContract,
        });
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const hashRateData = await fetchHashRateData();
      setHashRateArray({
        categories: hashRateData?.categories,
        series: hashRateData?.seriesData,
      });
    })();
  }, [fetchHashRateData, hrGraphStatus]);

  const stakeHandler = async () => {
    if (!account) {
      return showAlert("Error! Please connect your wallet.");
    }

    if (!amount || +amount <= 0) {
      return showAlert("Please enter amount");
    }

    if (chainId !== 1) {
      setTimeout(() => {
        setOpenNetworkSwitch(true);
      }, 1000);
      return showAlert("Please connect to ethereum mainnet");
    }
    try {
      setLoading(true);

      const tokenDecimals = await stakeTokenReadFunction("decimals");
      const checkAllowance = await stakeTokenReadFunction("allowance", [
        account,
        stakingAddress,
      ]);

      const checkTotalAllowance = +formatUnits(
        checkAllowance.toString(),
        +tokenDecimals?.toString()
      );
      if (+checkTotalAllowance < +amount) {
        await stakeTokenWriteFunction("approve", [
          stakingAddress,
          "999999999999999999999999999999",
        ]);
      }
      await stakingWriteFunction("stake", [
        parseUnits(amount.toString(), +tokenDecimals?.toString()),
      ]);

      // await axios.post(`${serverUrl}/api/stake`, {
      //   address: account?.toLowerCase(),
      //   amount: +amount,
      // });

      setAmount("");
      setLoading(false);
      showAlert("Staked successfully!", "success");
    } catch (e) {
      setLoading(false);
      console.log(e);
      showAlert(e?.shortMessage);
    }
  };
  const handleInputChange = (event) => {
    const input = event.target.value;
    const newValue = input?.replace(/[^0-9.]/g, "");
    setAmount(newValue);
  };
  return (
    <Box pt={{ xs: 16, md: 20 }} pb={{ xs: 5, md: 4 }}>
      <NetworkSwitch open={openNetworkSwitch} setOpen={setOpenNetworkSwitch} />
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Container maxWidth="xl">
        <Grid container justifyContent={"center"} mt={2}>
          <Grid item xs={12} sm={7} md={6} xl={5} lg={6}>
            <Stack
              sx={{
                borderRadius: "16px",
                background: "#0F101E",
                boxShadow: "0px 0px 8px 1px #408082",
                p: 2,
              }}
            >
              <Typography
                variant="h3"
                fontFamily="Outfit"
                sx={{
                  fontSize: "24px",
                  fontWeight: "600",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                Stake
              </Typography>
              <Stack my={3}>
                <StyledInputTwo
                  placeholder="Stake KSPNX"
                  sx={{ width: "100%" }}
                  value={amount}
                  onChange={handleInputChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box mr={-1.8}>
                          <Button
                            sx={{
                              width: { xs: "90px", sm: "120px" },
                              fontSize: "16px",
                              color: "#131315",
                              // py: 0.8,
                              height: "41px",
                              borderRadius: "6px",
                              background:
                                "linear-gradient(92.51deg, #68bcbb 0.48%, #68bcbb 50.74%, #68bcbb 100%)",
                              fontFamily: "Outfit",
                              textTransform: "capitalize",
                              "&:hover": {
                                background:
                                  "linear-gradient(92.51deg, #68bcbb 0.48%, #68bcbb 50.74%, #68bcbb 100%)",
                              },
                            }}
                            onClick={async () =>
                              account ? stakeHandler() : await open()
                            }
                          >
                            {account ? "Stake" : "Connect"}
                          </Button>
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Grid container mt={2} mb={4} spacing={4}>
          {/* chart 1 */}
          <Grid item xs={12} md={6}>
            <Stack
              sx={{
                background: "#0F101E",
                boxShadow: "0px 0px 8px 1px #408082",
                p: 1.5,
                borderRadius: "12px",
                height: { xs: "inherit", md: "100%" },
              }}
            >
              <Stack
                flexDirection={"row"}
                justifyContent={"space-Between"}
                alignItems={"center"}
              >
                <Typography
                  variant="h3"
                  fontFamily="Outfit"
                  sx={{
                    fontSize: { xs: "12px", sm: "14px" },
                    fontWeight: "500",
                    textTransform: "uppercase",
                    color: "#fff",
                    lineHeight: "1.5",
                  }}
                >
                  Kaspa Network Hash Rate <br /> ({hashRateValue} PH/S)
                </Typography>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    background: "#68BCBB",
                    border: "1px solid #fff",
                    borderRadius: "12px",
                  }}
                >
                  {[
                    { name: "Month", status: "1h" },
                    { name: "Year", status: "1d" },
                  ].map(({ status, name }, index, arr) => (
                    <Stack
                      onClick={() => setHrGraphStatus(status)}
                      key={name + index + Math.random()}
                      sx={{
                        borderRight:
                          arr?.length - 1 === index ? "none" : "1px solid #fff",
                        px: 0.5,
                        py: 0.5,
                        width: "60px",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        variant="body1"
                        fontFamily="Outfit"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        {name}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
              <Stack
                sx={{
                  height: "250px",
                }}
              >
                {hashRateArray?.categories?.length > 0 &&
                hashRateArray?.series?.length > 0 ? (
                  <Chart
                    lineColor="#68BCBB"
                    axisColorOne="#68BCBB"
                    axisColorTwo="#68BCBB"
                    days={hashRateArray?.categories}
                    values={hashRateArray?.series}
                    basicColor="#fff"
                    seriesName="HR"
                    hrGraphStatus={hrGraphStatus}
                  />
                ) : (
                  <Stack
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontFamily="Outfit"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#fff",
                      }}
                    >
                      No Data
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              sx={{
                background: "#0F101E",
                boxShadow: "0px 0px 8px 1px #408082",
                py: { xs: 3, md: 1.5 },
                px: 2,
                borderRadius: "12px",

                height: { xs: "inherit", md: "100%" },
              }}
            >
              <Typography
                variant="h3"
                fontFamily="Outfit"
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  textTransform: "uppercase",
                  color: "#fff",
                }}
              >
                User Balance
              </Typography>
              <UserValues userBalance={userBalance} />
            </Stack>
          </Grid>

          {/* chart 2 */}
          <Grid item xs={12} md={6}>
            <Stack
              sx={{
                p: 1.5,
                background: "#0F101E",
                boxShadow: "0px 0px 8px 1px #408082",
                borderRadius: "12px",
                mt: { xs: 0, md: 4 },
                height: { xs: "inherit", md: "100%" },
                // height: { xs: "inherit", md: "100%" },
              }}
            >
              <Stack
                flexDirection={"row"}
                justifyContent={"space-Between"}
                alignItems={"center"}
              >
                <Typography
                  variant="h3"
                  fontFamily="Outfit"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "uppercase",
                    color: "#fff",
                    lineHeight: "1.5",
                  }}
                >
                  Weekly Mining Profit <br /> (ETH)
                </Typography>
              </Stack>
              <Stack
                sx={{
                  height: "250px",
                }}
              >
                {usdtProfitArray?.series?.length > 0 ? (
                  <Chart
                    lineColor="#68BCBB"
                    axisColorOne="transparent"
                    axisColorTwo="transparent"
                    days={[""]}
                    values={usdtProfitArray?.series}
                    basicColor="#fff"
                    seriesName="ETH"
                  />
                ) : (
                  <Stack
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontFamily="Outfit"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#fff",
                      }}
                    >
                      No Data
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Grid>

          {/* chart 3 */}
          {/* <Grid item xs={12} md={6}>
            <Stack
              sx={{
                p: 1.5,
                boxShadow: "0px 0px 8px 1px #000",
                borderRadius: "12px",
                mt: { xs: 0, md: 4 },
                // height: { xs: "inherit", md: "100%" },
              }}
            >
              <Stack
                flexDirection={"row"}
                justifyContent={"space-Between"}
                alignItems={"center"}
              >
                <Typography
                  variant="h3"
                  fontFamily="Outfit"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "uppercase",
                    color: "#000",
                  }}
                >
                  Porfolio value <br /> (USDT)
                </Typography>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    background: "#68BCBB",
                    border: "1px solid #fff",
                    borderRadius: "12px",
                  }}
                >
                  {[
                    { value: 7, name: "Month" },
                    { value: 7, name: "Days" },
                  ].map(({ value, name }, index, arr) => (
                    <Stack
                      key={name + index}
                      sx={{
                        borderRight:
                          arr?.length - 1 === index ? "none" : "1px solid #fff",
                        px: 0.5,
                        py: 0.5,
                        width: "60px",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        variant="body1"
                        fontFamily="Outfit"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        {value} <br /> {name}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
              <Stack
                sx={{
                  height: "250px",
                  mt: 2,
                }}
              >
                <BarChart
                  barColor="#68BCBB"
                  days={dummyDays}
                  values={dummyValues}
                  basicColor="#000"
                />
              </Stack>
            </Stack>
          </Grid> */}

          {/* chart 4 */}
          {/* <Grid item xs={12} md={6}>
            <Stack
              sx={{
                background: "#0D1824",
                boxShadow: "0px 0px 8px 1px #000",
                p: 1.5,
                borderRadius: "12px",
                mt: { xs: 0, md: 4 },
                height: { xs: "inherit", md: "100%" },
                // height: { xs: "inherit", md: "100%" },
              }}
            >
              <Stack
                flexDirection={"row"}
                justifyContent={"space-Between"}
                alignItems={"center"}
              >
                <Typography
                  variant="h3"
                  fontFamily="Outfit"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "uppercase",
                    color: "#fff",
                  }}
                >
                  Staked KSPNX
                </Typography>
              </Stack>
              <Stack
                sx={{
                  height: "250px",
                }}
              >
                {stakedChartData?.categories?.length > 0 &&
                stakedChartData?.series?.length > 0 ? (
                  <Chart
                    lineColor="#68BCBB"
                    axisColorOne="#68BCBB"
                    axisColorTwo="#68BCBB"
                    days={stakedChartData?.categories}
                    values={stakedChartData?.series}
                    basicColor="#fff"
                    seriesName="Amount"
                  />
                ) : (
                  <Stack
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontFamily="Outfit"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#fff",
                      }}
                    >
                      No Data
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Grid> */}

          <Grid item xs={12} md={6}>
            <Stack
              sx={{
                background: "#0F101E",
                boxShadow: "0px 0px 8px 1px #408082",
                py: { xs: 2, md: 1.5 },
                px: 1.5,
                mt: { xs: 0, md: 4 },
                // mt: { xs: 0, md: 8 },

                borderRadius: "12px",
                height: { xs: "inherit", md: "100%" },
              }}
            >
              <Typography
                variant="h3"
                fontFamily="Outfit"
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  textTransform: "uppercase",
                  color: "#fff",
                }}
              >
                Transcaction History
              </Typography>
              <Stack>
                <DataTable data={userData} dataFunction={userFunction} />
              </Stack>
            </Stack>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <Stack
              sx={{
                background: "#0D1824",
                py: { xs: 4, md: 2 },
                px: 1.5,
                borderRadius: "12px",
                height: { xs: "inherit", md: "100%" },
              }}
            >
              <Typography
                variant="h3"
                fontFamily="Outfit"
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  textTransform: "uppercase",
                  color: "#fff",
                }}
              >
                Message
              </Typography>
              <Stack mt={2}>
                <Typography
                  variant="h3"
                  fontFamily="Outfit"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "20px",
                    color: "#fff",
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Vitae corrupti voluptatem officiis aliquid. Commodi saepe
                  fugit, iste numquam cumque corrupti, minus laboriosam ex quia
                  ea excepturi sunt repellendus natus nulla.Lorem ipsum dolor
                  sit amet, consectetur adipisicing elit. Vitae corrupti
                  voluptatem officiis aliquid. Commodi saepe fugit, iste numquam
                  cumque corrupti, minus laboriosam ex quia ea excepturi sunt
                  repellendus natus nulla.
                </Typography>
              </Stack>
            </Stack>
          </Grid> */}
        </Grid>
      </Container>
    </Box>
  );
}

export default Dashboard;
