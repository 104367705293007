import { useContext, useState, useEffect } from "react";
import Header from "./components/Header";
import HeroSection from "./pages/HeroSection";
import { useNetwork } from "wagmi";
import { AppContext } from "./utils";
import { Route, Routes } from "react-router-dom";
import NetworkSwitch from "./NetworkSwitch";
import Footer from "./components/Footer";
import Contribution from "./pages/Contribution";
import Brands from "./pages/Brands";
import Features from "./pages/Features";
import Charts from "./pages/Charts";
import RoadMap from "./pages/RoadMap";
import Team from "./pages/Team";
import GiveawaySection from "./pages/GiveawaySection";
import Giveaway from "./pages/Giveaway";
import Staking from "./pages/Staking";
import FAQs from "./pages/Faqs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import CookiesPolicy from "./pages/CookiesPolicy";
import Documents from "./pages/Documents";
import AddressCopy from "./pages/AddressCopy";
import FullScreenModal from "./components/SmallComponents/FullScreenModal";
import { Dashboard } from "./pages/dashboard";
function App() {
  const { account } = useContext(AppContext);
  const [openModal, setopenModal] = useState(true);
  const [openNetworkSwitch, setOpenNetworkSwitch] = useState(false);
  const { chain } = useNetwork();

  useEffect(() => {
    if (account && chain && chain?.id !== 56 && chain?.id !== 1) {
      setOpenNetworkSwitch(true);
    }
  }, [chain, account]);
  return (
    <>
      <FullScreenModal open={openModal} setOpen={setopenModal} />
      <NetworkSwitch open={openNetworkSwitch} setOpen={setOpenNetworkSwitch} />
      {/* Preloader */}

      <div id="preloader">
        <div className="spinner">
          <div className="rect1" />
          <div className="rect2" />
          <div className="rect3" />
          <div className="rect4" />
          <div className="rect5" />
        </div>
      </div>

      {/* Preloader */}
      {/* Scroll-top */}
      <button className="scroll-top scroll-to-target" data-target="html">
        <i className="fas fa-angle-up" />
      </button>
      {/* Scroll-top-end*/}
      {}
      {/* {pathname !== "/dashboard" && <Header />} */}
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <HeroSection />
              <AddressCopy />
              <Contribution />
              <Brands />
              <Features />
              <Charts />
              <RoadMap />
              {/* <Team /> */}
              <GiveawaySection />
              <FAQs />
              <Documents />
              <Footer />
            </>
          }
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/cookies-policy" element={<CookiesPolicy />} />
        <Route path="/giveaway" element={<Giveaway />} />
        <Route path="/staking" element={<Staking />} />
        <Route
          path="/dashboard"
          element={
            // <ResponsiveDrawer>
            <Dashboard />
            // </ResponsiveDrawer>
          }
        />
      </Routes>
    </>
  );
}

export default App;
