import React from "react";

function PrivacyPolicy() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <section
        className="banner-area banner-bg"
        style={{
          minHeight: "20vh",
          padding: '140px 0px 40px',
        }}
      >
        <div className="w-100">
          <div>
            <h1 className="text-center" style={{ fontFamily: "Outfit" }}>
              Privacy Policy
            </h1>
          </div>
        </div>
      </section>
      <section className="mt-2 py-5" style={{ fontFamily: "Outfit" }}>
        <div className="container">
          <p className="text-light">Privacy Policy for Kaspa Nexus</p>
          <p className="text-light">Last update date: May 2024</p>
          <h5>1. Introduction</h5>
          <p className="text-light">
            {
              'Welcome to Kaspa Nexus website. We appreciate your presence on our platform and are dedicated to ensuring the utmost protection and privacy of your information. This document ("Privacy Policy") outlines how Kaspa Nexus team ("Our Team", "We", "Our" or "Us") manage, utilize, and safeguard Personal Details of the users who interact with or use our services on our platform including, but not restricted to, '
            }
            <a href="https://www.kaspanexus.com">https://www.kaspanexus.com</a>
            {' (jointly, the "Platform").'}
          </p>
          <p className="text-light">
            {
              "You agree that this Privacy Policy does not apply to any third-party platforms, systems, or applications, even if they can be displayed, reached or seen to interact with our website. By using our services available via our Platform, you willingly consent to the management, utilization, and sharing of Personal Details as elaborated below."
            }
          </p>
          <h5>{"2. Acceptance of Terms"}</h5>
          <p className="text-light">
            {`By accessing or using the Our Site, you acknowledge that you have read, understood, and agree to abide by this Privacy Policy. The Privacy Policy may be changed at any later time which can be checked against the "Last Updated" date. By continuing to use the website (`}
            <a href="https://www.kaspanexus.com">https://www.kaspanexus.com</a>
            {`) after the updates, you inherently agree to the modifications of this Privacy Policy.`}
          </p>
          <h5>Responsible Information Handling</h5>
          <p className="text-light">
            We want to assure you that we do not trade or lease your personal
            data to external entities. In certain cases, we may engage with
            service providers, subject to stringent confidentiality agreements,
            to support our operational activities.
          </p>
          <h5>Information Collection</h5>
          <p className="text-light">
            We collect personal details, such as your name, email, and
            transaction particulars, that you voluntarily share with us.
            Additionally, automated processes assist us in aggregating
            non-identifiable data, such as IP addresses and browsing patterns.
            Without detracting from the generality of the above mentioned, we
            collect:
          </p>
          <ul>
            <li className="text-light">
              Financial Data: In the course of using our services, you may
              provide financial information, such as cryptocurrency wallet
              addresses, bank account information, and transaction history;
            </li>
            <li className="text-light">
              Technical Data: We may collect technical information about your
              device, including IP address, browser type, operating system, and
              usage data;
            </li>
            <li className="text-light">
              Cookies Data: We may use cookies and similar technologies to
              collect technical and marketing information to enhance and
              personalize your user experience. For more information about this,
              please refer to our cookies policy which is published on our
              platform;
            </li>
            <li className="text-light">
              Engagement Data: This can encompass, but is not confined to, data
              such as Your equipment (any equipment that can interface with our
              services such as a PC, a smartphone, or a digital pad) and
              internet address (IP address), browser specifics, browser version,
              pages of our offerings that you interact with, timestamps of your
              visits, the duration spent on those pages, and other diagnostic
              data. For those accessing our services via a mobile device, data
              like device type, unique device ID, IP address, mobile OS, browser
              type, and other such data may be gathered;
            </li>
            <li className="text-light">
              Communication Data: We may collect information related to your
              communication with us, including customer support inquiries,
              feedback, and chat messages;
            </li>
            <li className="text-light">
              Personal Data: In certain circumstances during your engagement
              with our services, we might need to collect some personal details
              about you.
            </li>
          </ul>
          <p className="text-light">
            Please note that when you get in contact with us, we might request
            you to share specific identifiable details that can be employed to
            recognize you. Identifiable details can be inclusive of: Full Name,
            Email Address and engagement data (Data that is gathered
            automatically from the use of our services or the infrastructure of
            our services itself) which is gathered automatically during the use
            of the services. Unless specified above, these types of information
            are not collected by default for all users, instead we employ these
            in the event of you trying to contact us in regards to requesting
            support for any arising situations with your wallet, purchase,
            post-presale claiming of tokens or staking.
          </p>
          <h5>Purposeful Use of Data</h5>
          <p className="text-light">
            We may retain the details and information gathered in alignment with
            this Privacy Policy for the duration of your engagement with our
            services or as required to provide our services, address disputes,
            establish legal defenses, conduct audits, pursue legitimate business
            objectives, and adhere to legal mandates.
          </p>
          <h5>User Rights and Transfer of Information</h5>
          <p className="text-light">
            We employ industry-standard security measures to protect your
            personal information from unauthorized access, disclosure,
            alteration, or destruction. However, no data transmission over the
            internet or storage method is completely secure, so we cannot
            guarantee absolute security.
          </p>
          <h5>Children Information</h5>
          <p className="text-light">
            The Services are not directed to nor intended for children under the
            age of 18 and we do not knowingly collect personal information from
            children. If you are a parent or guardian and believe your child has
            interacted with our services, thus enabling us to collect
            information about them, please get in contact with us. If we become
            aware that a child has provided us with personal information in
            violation of applicable law, we will delete any personal information
            we have collected.
          </p>
          <h5>User Rights and Transfer of Information</h5>
          <p className="text-light">
            We employ industry-standard security measures to protect your
            personal information from unauthorized access, disclosure,
            alteration, or destruction. However, no data transmission over the
            internet or storage method is completely secure, so we cannot
            guarantee absolute security.
          </p>
          <h5>Disclosure of Information</h5>
          <p className="text-light">
            Your personal information might be shared with or transferred to
            third parties for the purposes below:
          </p>
          <ul>
            <li className="text-light">
              Business Transactions: If Kaspa Nexus is involved in a merger,
              acquisition or asset sale, your Personal Information may be
              transferred to the company involved in these processes;
            </li>
            <li className="text-light">
              Law Enforcement: Under certain circumstances, Kaspa Nexus may be
              required to disclose your Personal Information if required to do
              so by codified law or in response to valid requests by public
              authorities (e.g. a court or a government agency);
            </li>
            <li className="text-light">
              Other Legal Requirements: Kaspa Nexus may disclose your Personal
              Information in the good faith belief that such action is necessary
              to: comply with a legal obligation, protect and defend the rights
              or property of the Company, prevent or investigate possible
              wrongdoing in connection with the Service, protect the personal
              safety of Users of the Service or the public, protect itself
              against legal liability.
            </li>
          </ul>
          <h5>Links to Third Parties</h5>
          <p className="text-light">
            Our Service may contain links to other websites that are not
            operated by Us. It is your responsibility to inform about the
            Privacy Policy and terms of Use of the third-party provider before
            accessing their services displayed on our website. If you click on
            their websites, it is implied you agree to be bound by their terms
            and policies. We have no control over and assume no responsibility
            for the content, privacy policies or practices of any third-party
            sites or services.
          </p>
          <h5>Evolving Framework</h5>
          <p className="text-light">
            We may update this Privacy Policy periodically to reflect changes in
            our practices or legal requirements. The last update of this Privacy
            Policy will be reflected in the date shown in "Last updated" at the
            beginning of Privacy Policy. By continuing to use our website after
            that date, you agree with the latest changes made in this Privacy
            Policy.
          </p>
          <h5>Contact Information</h5>
          <p className="text-light">
            For any queries regarding this Privacy Policy or If you have any
            questions, concerns, or requests related to this Privacy Policy or
            your personal data please do not hesitate to contact us at{" "}
            <a href="mailto:support@kaspanexus.com">support@kaspanexus.com</a>.
          </p>
          <hr />
          <p className="text-center text-light">
            Copyright © {currentYear} Kaspa Nexus. All rights reserved.
          </p>
        </div>
      </section>
    </>
  );
}

export default PrivacyPolicy;
