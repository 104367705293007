import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import StakingTable from "../components/SmallComponents/StakingTable";
import {
  StyledButton,
  StyledInput,
  ToastNotify,
} from "../components/SmallComponents/AppComponents";
import { AppContext, getNetworkContract } from "../utils";
import {
  preSaleReadFunction,
  preSaleWriteFunction,
  tokenReadFunction,
} from "../ConnectivityAssets/hooks";
import { formatUnits } from "viem";
import Loading from "../components/SmallComponents/loading";

function Staking() {
  const currentYear = new Date().getFullYear();
  const { account, chainId } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const [userData, setUserData] = useState([]);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const showAlert = (message, severity = "error") => {
    setAlertState({
      open: true,
      message,
      severity,
    });
  };
  const [stakeInfo, setStakeInfo] = useState({
    totalStaked: 0,
    totalStakers: 0,
  });

  const toLocalFormat = (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const stakeInfoFunction = async () => {
    try {
      const networkContractObj = getNetworkContract(1);
      let dec = await tokenReadFunction("decimals");
      dec = +dec?.toString();
      const stakeAmountData = await preSaleReadFunction(
        networkContractObj,
        "totalStakedAmount"
      );

      const uniqBuyersData = await preSaleReadFunction(
        networkContractObj,
        "totalUsersCount"
      );
      let tStaked = +parseFloat(
        +formatUnits(stakeAmountData?.toString(), dec)
      ).toFixed(0);
      setStakeInfo({
        totalStaked: toLocalFormat(tStaked),
        totalStakers: +uniqBuyersData?.toString(),
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    stakeInfoFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userValues = useCallback(async () => {
    try {
      const networkContractObj = getNetworkContract(1);
      const userStakeInfo = await preSaleReadFunction(
        networkContractObj,
        "users",
        [account]
      );
      const dt = [];
      for (let i = 0; i < +userStakeInfo[7]?.toString(); i++) {
        const userData = await preSaleReadFunction(
          networkContractObj,
          "userStakes",
          [account, i]
        );
        const obj = {
          plainIndex: i,
          stakedTokens: +parseFloat(
            formatUnits(userData[0]?.toString(), 18)
          )?.toFixed(2),
          claimedTokens: +parseFloat(
            formatUnits(userData[1]?.toString(), 18)
          )?.toFixed(2),
          stakeTime: +userData[2]?.toString(),
          claimedReward: +parseFloat(
            formatUnits(userData[4]?.toString(), 18)
          )?.toFixed(2),
          usStakeStatus: +userData[5]?.toString(),
          usStakeTime: +userData[6]?.toString(),
        };
        dt.push(obj);
      }
      setUserData(dt);
    } catch (e) {
      console.log(e);
    }
  }, [account, chainId]);

  useEffect(() => {
    if (account) {
      userValues();
    }
  }, [account, chainId, userValues]);

  const stakeArray = [
    {
      title: "Total Staked",
      value: stakeInfo?.totalStaked,
    },
    {
      title: "Apy",
      value: "20%",
    },
    {
      title: "Total Stakers",
      value: stakeInfo?.totalStakers,
    },
  ];
  console.log(stakeArray);
  const unStakeHandler = async (index) => {
    if (!account) {
      return showAlert("Error! Please connect Total wallet.");
    }
    if (+chainId !== 1) {
      return showAlert("Error! Please switch to Ethereum network.");
    }
    try {
      const networkContractObj = getNetworkContract(chainId);
      setLoading(true);
      await preSaleWriteFunction(networkContractObj, "unStake", [
        index?.toString(),
      ]);
      stakeInfoFunction();
      userValues();
      setLoading(false);
      showAlert("You have successfully UnStaked!", "success");
    } catch (e) {
      setLoading(false);
      showAlert(e?.shortMessage);
    }
  };
  return (
    <>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Loading loading={loading} />
      <section
        className="banner-area banner-bg"
        style={{
          minHeight: "20vh",
          padding: "140px 0px 40px",
        }}
      >
        <div className="w-100">
          <div>
            <h1 className="text-center" style={{ fontFamily: "Outfit" }}>
              KSPNX tokens staking
            </h1>
          </div>
        </div>
      </section>
      <Box pt={5} pb={10} sx={{ backdropFilter: "blur(20px)" }}>
        <Container maxWidth="xl">
          <Box
            sx={{
              maxWidth: "470px",
              margin: "auto",
              paddingTop: "20px",
              fontFamily: "Outfit",
            }}
          >
            <h5 className="text-center">WELCOME TO KSPNX STAKING</h5>
            <p className="text-center text-light mb-5">
              By staking Your $KSPNX Tokens You Can Earn <b>FREE</b> KSPNX
              tokens (and A Generous APY) Just by Holding (Staking) your tokens!
              Don’t wait and stake your tokens NOW!
              <br />
              Staking is only possible if you buy KSPNX tokens on ETH network.
              <br />
              If you buy tokens on BNB network, you can't enjoy staking rewards.
            </p>
          </Box>
          <Grid container justifyContent={"center"} spacing={2}>
            {stakeArray?.map(({ title, value }) => (
              <Grid item xs={12} sm={6} md={4} key={title}>
                <Box
                  sx={{
                    background:
                      "linear-gradient(92.51deg, #68bcbb 0.48%, #68bcbb 50.74%, #68bcbb 100%)",
                    p: { xs: 2, sm: 3 },
                    borderRadius: "20px",
                    mt: 3,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "26px",
                      textAlign: "center",
                      fontWeight: "800",
                      color: "#000000",
                      fontFamily: "outfit",
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "36px",
                      textAlign: "center",
                      fontWeight: "800",
                      color: "#000000",
                      fontFamily: "outfit",
                      mt: 2,
                    }}
                  >
                    {value}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
        {/* <Container maxWidth="sm">
          <Grid container pt={5}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "outfit",
                  fontSize: { xs: "30px", md: "40px" },
                  fontWeight: "800",
                  lineHeight: "48px",
                  color: "#ffff",
                  textAlign: "center",
                  mb: 2,
                }}
              >
                Stake Period 365 Days
              </Typography>

              <Box
                sx={{
                  background:
                    "linear-gradient(92.51deg, #68bcbb 0.48%, #68bcbb 50.74%, #68bcbb 100%)",
                  p: { xs: 2, sm: 3 },
                  borderRadius: "20px",
                  mt: 3,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "36px",
                    textAlign: "center",
                    fontWeight: "800",
                    color: "#000000",
                    fontFamily: "outfit",
                  }}
                >
                  Stake
                </Typography>
                <Box mt={2}>
                  <StyledInput
                    borderColor={"#312E37"}
                    color="#EBE9ED"
                    type="text"
                    placeholder="0"
                    // value={amount}
                    // onChange={handleInputChange}
                  />
                </Box>

                <Box mt={3} />
                <Button
                  sx={{
                    color: "#dfdfdf",
                    width: "100%",
                    background: "#0F101E",
                    fontSize: "22px",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    textTransform: "capitalize",
                    fontFamily: "Outfit",
                    borderRadius: "12px",
                    fontWeight: "600",
                    "&.Mui-disabled": {
                      color: "#979EA7",
                    },
                    "&:hover": {
                      background: "#0F101E",
                    },
                  }}
                  disabled={chainId !== 1}

                  // onClick={stakeHandler}
                >
                  Stake
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container> */}
        <Container maxWidth="xl">
          <Grid container pt={5}>
            <Grid item xs={12}>
              <Box
                sx={{
                  background:
                    "linear-gradient(92.51deg, #68bcbb 0.48%, #68bcbb 50.74%, #68bcbb 100%)",
                  p: { xs: 2, sm: 3 },
                  borderRadius: "20px",
                  mt: 3,
                }}
              >
                <StakingTable data={userData} unStakeHandler={unStakeHandler} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <section className="pb-3">
        <div className="container">
          <hr />
          <p className="text-center text-light">
            Copyright © {currentYear} Kaspa Nexus. All rights reserved.
          </p>
        </div>
      </section>
    </>
  );
}

export default Staking;
