import React from "react";

function FAQs() {
  return (
    <>
      {/* faq-area */}
      <section className="faq-area" id="faq">
        <div className="container">
          <div className="faq-inner-wrap">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb-70">
                  <h2 className="title">Frequently Asked Questions</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="faq-wrap">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item active">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          What is Kaspa Nexus?
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            {
                              "Kaspa Nexus is a pioneering cryptocurrency cloud mining platform that utilizes a Decentralized Stake-to-Mine (DSM) approach, integrating the efficiency of the Kaspa blockchain with an innovative tokenized model. It aims to democratize the mining process, making it accessible and efficient for all participants."
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne1"
                          aria-expanded="true"
                          aria-controls="collapseOne1"
                        >
                          Why Kaspa and not Bitcoin mining?
                        </button>
                      </h2>
                      <div
                        id="collapseOne1"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <ul>
                            <li>
                              <p>
                                <strong>Profitability Advantage:</strong> Kaspa
                                is currently (April 2024) considered more
                                profitable to mine with ASICs compared to
                                Bitcoin. The younger Kaspa network has less
                                competition among miners, offering potentially
                                higher returns.
                              </p>
                            </li>
                            <li>
                              <p>
                                <strong>Lower Barrier to Entry:</strong> Kaspa
                                ASIC miners may be generally less expensive than
                                their Bitcoin counterparts. This is because
                                Kaspa ASICs are a newer technology with
                                potentially less competition among
                                manufacturers.
                              </p>
                            </li>
                            <li>
                              <p>
                                <strong>Efficiency:</strong> Kaspa's blockDAG
                                design is thought to be more efficient than
                                Bitcoin's blockchain. This could translate to
                                lower energy consumption for Kaspa ASIC miners
                                compared to Bitcoin ASIC miners, making Kaspa
                                mining potentially more environmentally
                                friendly.
                              </p>
                            </li>
                          </ul>
                          <p>
                            {
                              "*The expected ROI and APY for Kaspa mining would depend on various factors such as network difficulty, market demand for Kaspa, hardware costs, electricity costs, and more. Conducting thorough research and staying informed about developments in the Kaspa ecosystem would be essential for making informed decisions regarding investment in Kaspa mining."
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          {
                            "How does the Decentralized Stake-to-Mine (DSM) protocol work?"
                          }
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            The DSM protocol allows users to stake KSPNX tokens
                            in exchange for mining credits, which can then be
                            used to mine Kaspa directly on the platform without
                            the need for traditional mining hardware. This
                            process not only simplifies participation but also
                            enhances security and transparency.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          What makes Kaspa Nexus different from other mining
                          platforms?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Kaspa Nexus will set itself, apart with its unique
                            DSM protocol, by providing an accessible, secure,
                            and environmentally mining solution
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          What is the maximum number of KSPNX tokens created?
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>5,000,000,000 KSPNX tokens will be created.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          What network does Kaspa Nexus operate on?
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            {
                              "Kaspa Nexus operated on Ethereum blockchain (ERC-20) network. This choice of network ensures optimal accessibility and participation for our community members."
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          Has the smart contract undergone an audit?
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            The Kaspa Nexus smart contract underwent a
                            comprehensive audit conducted by the auditing firm,
                            Cyberscope. This meticulous examination ensures the
                            platform's robust security, unwavering reliability,
                            and integrity, instilling a sense of confidence
                            among investors in the Kaspa Nexus ecosystem.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                          How can I participate in Kaspa Nexus?
                        </button>
                      </h2>
                      <div
                        id="collapseSeven"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            You can participate by acquiring KSPNX tokens during
                            the presale or from the market, staking them on the
                            Kaspa Nexus platform to earn mining credits or just
                            purchase with them mining credits in our platform,
                            and then using those credits to mine Kaspa. Subject
                            to our Term of Use and White Paper, the
                            participation is open to anyone interested in
                            contributing to and benefiting from the
                            cryptocurrency ecosystem
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseEight"
                          aria-expanded="false"
                          aria-controls="collapseEight"
                        >
                          When and where will I receive my KSPNX tokens?
                        </button>
                      </h2>
                      <div
                        id="collapseEight"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            You will be able to claim your KSPNX tokens after
                            the presale concludes. This claiming process will
                            take place on Kaspa Nexus's official website.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseNine"
                          aria-expanded="false"
                          aria-controls="collapseNine"
                        >
                          How many presale stages are there?
                        </button>
                      </h2>
                      <div
                        id="collapseNine"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>Kaspa Nexus's presale consists of 10 stages.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTen"
                          aria-expanded="false"
                          aria-controls="collapseTen"
                        >
                          What are the benefits of mining with Kaspa Nexus?
                        </button>
                      </h2>
                      <div
                        id="collapseTen"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Benefits include lower entry barriers, decentralized
                            mining, enhanced transparency, maximized efficiency,
                            and empowerment of participants through a stake in
                            the platform.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseEleven"
                          aria-expanded="false"
                          aria-controls="collapseEleven"
                        >
                          Are there any incentives for early participants?
                        </button>
                      </h2>
                      <div
                        id="collapseEleven"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Yes, early participants in the KSPNX token presale
                            can enjoy discounted rates and stake their KSPNX
                            tokens to earn more tokens during the presale. Early
                            participants will also get early access to the
                            mining platform, and the opportunity to contribute
                            to the development and expansion of Kaspa Nexus,
                            shaping its future direction.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwelve"
                          aria-expanded="false"
                          aria-controls="collapseTwelve"
                        >
                          When and where will KSPNX launch?
                        </button>
                      </h2>
                      <div
                        id="collapseTwelve"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Following the presale, KSPNX will launch on multiple
                            centralized exchanges, including at top-tier
                            exchanges. Stay tuned for official announcements
                            about the specific launch date and trading platforms
                            by following our social media channels.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThirteen"
                          aria-expanded="false"
                          aria-controls="collapseThirteen"
                        >
                          How does Kaspa Nexus address environmental concerns?
                        </button>
                      </h2>
                      <div
                        id="collapseThirteen"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Kaspa Nexus is designed to minimize the carbon
                            footprint of mining activities, aligning with global
                            efforts to combat climate change. This is achieved
                            through the energy-efficient DSM protocol and the
                            platform's overall sustainability-focused approach.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFourteen"
                          aria-expanded="false"
                          aria-controls="collapseFourteen"
                        >
                          What is the role of KSPNX tokens within the Kaspa
                          Nexus ecosystem?
                        </button>
                      </h2>
                      <div
                        id="collapseFourteen"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            KSPNX tokens serve multiple purposes, including
                            facilitating the stake-to-mine process, purchase of
                            mining credits and accessing exclusive platform
                            features. They are central to the Kaspa Nexus
                            ecosystem
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFifteen"
                          aria-expanded="false"
                          aria-controls="collapseFifteen"
                        >
                          How can I stay updated on Kaspa Nexus developments?
                        </button>
                      </h2>
                      <div
                        id="collapseFifteen"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Stay updated by joining our community on social
                            media, subscribing to our newsletter, and regularly
                            checking our website for the latest news, and
                            updates.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSixteen"
                          aria-expanded="false"
                          aria-controls="collapseSixteen"
                        >
                          Who can I contact for more information or support?
                        </button>
                      </h2>
                      <div
                        id="collapseSixteen"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            For more information or support, please reach out to
                            us via our website's contact form, join our
                            community forums, or message us on social media. Our
                            team is dedicated to providing timely assistance and
                            answering any questions you may have.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* faq-area-end */}
    </>
  );
}

export default FAQs;
