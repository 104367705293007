import React from "react";

function Team() {
  return (
    <>
      {/* team-area */}
      <section
        className="team-area team-bg"
        data-background="assets/img/bg/team_bg.png"
      >
        <div className="container-fluid p-0">
          <div className="row g-0">
            <div className="col-12">
              <div className="section-title text-center mb-50">
                <h2 className="title">Meet the Brick Veneers</h2>
              </div>
            </div>
          </div>
          <div className="team-item-wrap">
            <div className="row team-active g-0">
              <div className="col">
                <div className="team-item">
                  <div className="team-thumb">
                    <img src="assets/img/team/team_img01.png" alt="" />
                    <a href="#" className="team-social">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                  <div className="team-content">
                    <h2 className="title">Floyd Miles</h2>
                    <span>Marketer</span>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="team-item">
                  <div className="team-thumb">
                    <img src="assets/img/team/team_img02.png" alt="" />
                    <a href="#" className="team-social">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                  <div className="team-content">
                    <h2 className="title">Eleanor Pena</h2>
                    <span>Founder &amp; CEO</span>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="team-item">
                  <div className="team-thumb">
                    <img src="assets/img/team/team_img03.png" alt="" />
                    <a href="#" className="team-social">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                  <div className="team-content">
                    <h2 className="title">Arlene McCoy</h2>
                    <span>Technology Officer</span>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="team-item">
                  <div className="team-thumb">
                    <img src="assets/img/team/team_img04.png" alt="" />
                    <a href="#" className="team-social">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                  <div className="team-content">
                    <h2 className="title">Robert Fox</h2>
                    <span>Financial Officer</span>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="team-item">
                  <div className="team-thumb">
                    <img src="assets/img/team/team_img05.png" alt="" />
                    <a href="#" className="team-social">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                  <div className="team-content">
                    <h2 className="title">Jacob Jones</h2>
                    <span>Head of Blockchain</span>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="team-item">
                  <div className="team-thumb">
                    <img src="assets/img/team/team_img06.png" alt="" />
                    <a href="#" className="team-social">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                  <div className="team-content">
                    <h2 className="title">Albert Flores</h2>
                    <span>Product Designer</span>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="team-item">
                  <div className="team-thumb">
                    <img src="assets/img/team/team_img07.png" alt="" />
                    <a href="#" className="team-social">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                  <div className="team-content">
                    <h2 className="title">Devon Lane</h2>
                    <span>Visual Designer</span>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="team-item">
                  <div className="team-thumb">
                    <img src="assets/img/team/team_img08.png" alt="" />
                    <a href="#" className="team-social">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                  <div className="team-content">
                    <h2 className="title">Jerome Bell</h2>
                    <span>Legal &amp; DPO</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="read-more-btn text-center mt-70">
              <a href="contact.html" className="btn">
                All Team Member
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* team-area-end */}
    </>
  );
}

export default Team;
