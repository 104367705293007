import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

function GiveawaySection() {
  return (
    <Box component="section" className="pt-5 mt-5">
      <Container>
        <Box className="section-title">
          <Typography
            fontFamily="Outfit"
            className="title text-center"
            variant="h3"
          >
            Kaspa Nexus’s $5 Million Giveaway
          </Typography>
          <Typography
            fontFamily="Outfit"
            className="text-light px-1 mt-1 text-center"
          >
            Ready to Win Big? Enter the Massive $5M Kaspa Nexus Giveaway!
            Participate and complete all the entries for your shot at claiming
            the grand prize. Don't miss out on your chance to be the lucky
            winner!
          </Typography>
        </Box>
      </Container>
      <Grid container>
        <Grid item xs={12} md={6} mt={2}>
          <Box className="text-center mt-3">
            <iframe
              src="https://gleam.io/C5kth/kaspa-nexus-5-million-giveaway"
              className="e-widget no-button"
              title="Giveaway Iframe"
              width="100%"
              height="750px"
            ></iframe>
          </Box>
        </Grid>
        <Grid
          mt={2}
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              width: { xs: "300px", md: "500px" },
              height: "auto",
              maxWidth: "100%",
            }}
            src="assets/img/images/giveaway.png"
            alt="globe"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default GiveawaySection;
