import React from "react";
import XIcon from "@mui/icons-material/X";
import { HashLink } from "react-router-hash-link";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      {/* footer-area */}
      <footer>
        <div
          className="footer-area footer-bg"
          data-background="assets/img/bg/footer_bg.png"
        >
          <div className="container">
            <div className="footer-top">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                  <div className="footer-widget">
                    <h4 className="fw-title">NAVIGATION</h4>
                    <div className="footer-link">
                      <ul className="list-wrap">
                        <li>
                          <a href="whitepaper.pdf">White Paper</a>
                        </li>
                        <li>
                          <HashLink
                            smooth
                            to="/#home"
                            style={{ textDecoration: "none", margin: "auto" }}
                          >
                            <a>Join Our Presale</a>
                          </HashLink>
                        </li>
                        <li>
                          <HashLink
                            smooth
                            to="/#faq"
                            style={{ textDecoration: "none", margin: "auto" }}
                          >
                            <a>FAQs</a>
                          </HashLink>
                        </li>
                        <li>
                          <HashLink
                            smooth
                            to="/#tokenomics"
                            style={{ textDecoration: "none", margin: "auto" }}
                          >
                            <a>Tokenomics</a>
                          </HashLink>
                        </li>
                        <li>
                          <HashLink
                            smooth
                            to="/#roadMap"
                            style={{ textDecoration: "none", margin: "auto" }}
                          ></HashLink>
                          <a>Roadmap</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-2 col-md-4 col-sm-6">
                  <div className="footer-widget">
                    <h4 className="fw-title">LEGALS</h4>
                    <div className="footer-link">
                      <ul className="list-wrap">
                        <li>
                          <a href="/terms-and-conditions">Terms & Conditions</a>
                        </li>
                        <li>
                          <a href="/privacy-policy">Privacy Policy</a>
                        </li>
                        <li>
                          <a href="/cookies-policy">Cookies</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4">
                  <div className="footer-widget">
                    <h4 className="fw-title">Socials</h4>
                    <div className="footer-link">
                      <div className="d-flex">
                        <div className="pe-2">
                          <a
                            target="_blank"
                            href="https://twitter.com/kaspa_nexus"
                          >
                            <XIcon sx={{ fontSize: "20px", color: "#fff" }} />
                          </a>
                        </div>
                        <div className="px-2">
                          <a
                            target="_blank"
                            href="https://www.facebook.com/groups/kaspanexus"
                          >
                            <i
                              style={{ fontSize: "18px" }}
                              className="text-light fab fa-facebook-f"
                            ></i>
                          </a>
                        </div>
                        <div className="px-2">
                          <a
                            target="_blank"
                            href="https://t.me/kaspanexusoffical"
                          >
                            <i
                              style={{ fontSize: "16px" }}
                              className="text-light fas fa-paper-plane"
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-8">
                  <div className="footer-widget">
                    <h4 className="fw-title">KASPA NEXUS UPDATES & NEWS</h4>
                    <div className="footer-newsletter">
                      <p>
                        Stay up-to-date with the most recent news and updates.
                        Sign up with your email now!
                      </p>
                      <form action="#">
                        <input type="text" placeholder="Info@gmail.com" />
                        <button type="submit">
                          <i className="fas fa-paper-plane" />
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="copyright-text"
                    style={{ fontFamily: "Outfit" }}
                  >
                    <p>
                      Copyright © {currentYear} Kaspa Nexus. All rights
                      reserved.
                    </p>
                    <p className="mt-3">
                      Disclaimer: Cryptocurrency may be unregulated in your
                      jurisdiction. The value of cryptocurrencies may go down as
                      well as up. Profits may be subject to capital gains or
                      other taxes applicable in your jurisdiction.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-shape-wrap">
            <img
              src="assets/img/images/footer_shape01.png"
              alt=""
              className="alltuchtopdown"
            />
            <img
              src="assets/img/images/footer_shape02.png"
              alt=""
              className="leftToRight"
            />
          </div>
        </div>
      </footer>
      {/* footer-area-end */}
    </>
  );
}
